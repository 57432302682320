import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToggleResolution } from "@metronome/features/UpdateResolution";
import { useIsMobile } from "@metronome/hooks/useResponsive";
import { EResolution } from "@metronome/types/Resolution";
import type {
	EStepInstanceType,
	IStepInstanceLight,
} from "@metronome/types/StepInstance";
import { EStepTimeliness } from "@metronome/types/Timeliness";
import { getTimelinessFromStepInstance } from "@metronome/utils/timeliness";
import { ClockIcon, LockClosedIcon } from "@radix-ui/react-icons";
import clsx from "clsx";
import { StepInstanceIcon } from "../IconStepInstance";
import styles from "./singleStepInstance.module.scss";
import { Link, useParams } from "@tanstack/react-router";

type StepResolutionProps = {
	resolution?: EResolution;
	stepInstanceId: string;
	processInstanceId: string;
};
const StepResolution: React.FC<StepResolutionProps> = ({
	resolution,
	stepInstanceId,
	processInstanceId,
}) => {
	switch (resolution) {
		case EResolution.enum.cancelled:
			return (
				<FontAwesomeIcon
					className={styles.iconCancel}
					icon={["fas", "xmark"]}
				/>
			);
		case EResolution.enum.done:
		case EResolution.enum.notStarted:
			return (
				<ToggleResolution
					stepInstanceId={stepInstanceId}
					processInstanceId={processInstanceId}
					resolution={resolution}
				/>
			);
		case EResolution.enum.onHold:
			return (
				<FontAwesomeIcon
					className={styles.iconPause}
					icon={["far", "circle-pause"]}
				/>
			);
		case EResolution.enum.inProgress:
			return (
				<FontAwesomeIcon
					className={styles.iconInProgress}
					icon={["fas", "arrows-rotate"]}
				/>
			);
		default:
			return (
				<FontAwesomeIcon
					className="text-transparent"
					icon={["far", "square"]}
				/>
			);
	}
};

type Props = {
	stepInstance: IStepInstanceLight;
	type: EStepInstanceType;
	name: string;
	isInAGroup?: boolean;
};

export const SingleStepInstance: React.FC<Props> = ({
	stepInstance,
	type,
	isInAGroup = false,
	name,
}) => {
	const { processId, stepId } = useParams({
		strict: false,
	});

	const timeliness = getTimelinessFromStepInstance(
		stepInstance.schedule,
		undefined,
		stepInstance.type,
	);

	const isMobile = useIsMobile();

	const isSelected = stepId === stepInstance.id;

	return (
		<Link
			from="/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/$stepId"
			to={isMobile ? "../../step/$stepId" : "../$stepId"}
			params={{ stepId: stepInstance.id }}
		>
			<div
				className={clsx({
					"bg-blue-200": isSelected,
					[styles.isInAGroup]: isInAGroup,
					[`${styles.container} group/instance hover:bg-blue-100 rounded-r-md`]: true,
				})}
			>
				<StepResolution
					resolution={stepInstance.resolution}
					stepInstanceId={stepInstance.id}
					processInstanceId={processId as string}
				/>
				{!isInAGroup && (<> </>
					// <span className={styles.stepName}>
					// 	<StepInstanceIcon type={type} />
					// </span>
				)}
				<span className="truncate">{name}</span>
				{!!stepInstance.isBlocked && (
					<LockClosedIcon className="min-w-[15px]" />
				)}
				{!!(
					stepInstance.resolution !== EResolution.enum.done &&
					timeliness === EStepTimeliness.enum.late
				) && <ClockIcon className="text-red-700 min-w-[15px]" />}
				{!!stepInstance.isFlagged && (
					<div className={styles.flagContainer}>
						<FontAwesomeIcon
							className={styles.flag_alert}
							icon={["fas", "flag"]}
							fixedWidth
						/>
					</div>
				)}
			</div>
		</Link>
	);
};
