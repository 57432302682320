import useSaveTableViews from "@metronome/api/useSaveTableViews";
import useWorkspaceViews from "@metronome/api/useWorkspaceViews";
import { useActiveViewData } from "@metronome/context/ActiveViewData";
import type React from "react";
import { ProcessInstancesTab } from "./ProcessInstancesTab";
import type { UserPreference } from "@metronome/types/UserPreference";
import { useGetStepInstancesFilters } from "@metronome/api/useStepInstance";
import type { DateRange } from "react-day-picker";

type Props = {
	processStreamId: string;
	preferences?: UserPreference;
	preferenceKey: string;
	range?: DateRange;
};

export const UserProcessConfigLoader: React.FC<Props> = ({
	processStreamId,
	preferences,
	preferenceKey,
	range,
}) => {
	const { activeView } = useActiveViewData();

	const { data: savedViews } = useSaveTableViews(preferenceKey);
	const { data: workspaceViews } = useWorkspaceViews(preferenceKey);
	// we're using the stepInstancesFilters in processes because Labels are common to both
	// endpoint should probably be refactored to be more generic.
	const { data: filters } = useGetStepInstancesFilters();

	return (
		<ProcessInstancesTab
			streamId={processStreamId}
			key={preferenceKey + activeView?.id}
			preferenceKey={preferenceKey}
			preferences={preferences}
			savedViews={savedViews}
			workspaceViews={workspaceViews}
			labelsFilter={filters?.labels ?? []}
			range={range}
		/>
	);
};
