import type { AxiosError, AxiosResponse } from "axios";
import {
	type UseMutationResult,
	type UseQueryResult,
	useMutation,
	useQuery,
	useQueryClient,
	keepPreviousData,
	queryOptions,
} from "@tanstack/react-query";
import { toast } from "sonner";
import { z } from "zod";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type { IStage } from "@metronome/types/Gate";
import type {
	IProcessInstance,
	UserSelectedProcessState,
} from "@metronome/types/ProcessInstance";
import { useIntl } from "react-intl";

import { apiGet, apiPut } from "./api";
import type { ExpectedErrorResponseType } from "./api";
import { useOrganizationData } from "@metronome/context/OrganizationData";
import type { IPaginatedResults } from "@metronome/types/PaginatedResponse";
import type { ResourcesArray } from "@metronome/types/Resources";

const processStreamsInstances = "processStreamsInstances";

type BaseProcessParams = {
	streamId: string;
	page: number;
	pageSize: number;
	search?: string;
	sortBy?: string;
	sortOrder?: "asc" | "desc";
	labelIds?: string[];
};

interface BaseParams {
	workspaceId: string;
	processInstanceId: string;
}

export const processInstanceKeys = {
	all: (workspaceId: string) => [workspaceId, "process-instances"] as const,
	myProcessInstancesAll: (
		workspaceId: string,
		organizationId?: string | null,
	) => [workspaceId, organizationId ?? "", "my-process-instances"] as const,
	processInstancesFromBatch: (
		workspaceId: string,
		batchId?: string,
		organizationId?: string | null,
		params?: Partial<BaseProcessParams>,
	) =>
		[
			workspaceId,
			organizationId ?? "",
			"process-instances-from-batch",
			batchId,
			params,
		] as const,
	processInstancesFromCluster: (
		workspaceId: string,
		streamId: string,
		clusterId?: string,
		organizationId?: string | null,
		params?: Partial<BaseProcessParams>,
	) =>
		[
			workspaceId,
			streamId,
			organizationId ?? "",
			"process-instances-from-cluster",
			clusterId,
			params,
		] as const,
	single: (workspaceId: string, processInstanceId?: string) =>
		[...processInstanceKeys.all(workspaceId), processInstanceId] as const,
	stages: (workspaceId: string, processInstanceId?: string) =>
		[
			...processInstanceKeys.single(workspaceId, processInstanceId),
			"stages",
		] as const,
	base: (
		workspaceId: string,
		processStreamsInstances: "processStreamsInstances",
		processStreamId?: string,
	) => [workspaceId, processStreamsInstances, processStreamId] as const,
	params: (
		workspaceId: string,
		processStreamsInstances: "processStreamsInstances",
		processStreamId: string,
		filters: Partial<BaseProcessParams>,
	) => [
		...processInstanceKeys.base(
			workspaceId,
			processStreamsInstances,
			processStreamId,
		),
		filters,
	],
};

export const stagesQueryOptions = (
	workspaceId: string,
	processInstanceId?: string,
) =>
	queryOptions({
		queryKey: processInstanceKeys.stages(workspaceId, processInstanceId),
		queryFn: () =>
			apiGet<IStage[]>(
				`ws/${workspaceId}/process-instances/${processInstanceId}/steps`,
			),
		enabled: !!processInstanceId,
	});

export function useStages(
	processInstanceId?: string,
): UseQueryResult<IStage[] | undefined, Error> {
	const workspaceId = useWorkspaceId();
	if (!workspaceId) {
		throw new Error("useStages: workspaceId is not defined");
	}
	return useQuery(stagesQueryOptions(workspaceId, processInstanceId));
}

export function useStepBulkResolution(
	processInstanceId: string,
): UseMutationResult<unknown, AxiosError<ExpectedErrorResponseType>, string> {
	const workspaceId = useWorkspaceId();
	const intl = useIntl();
	const queryClient = useQueryClient();
	return useMutation({
		mutationFn: (stepId: string) =>
			apiPut(
				`ws/${workspaceId}/process-instances/${processInstanceId}/steps/${stepId}/resolve`,
				{},
			),

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: processInstanceKeys.stages(workspaceId, processInstanceId),
			});
			toast.success(intl.formatMessage({ id: "SUCCESS" }));
		},

		onError: (error) => {
			toast.error(`Error: ${error.message}`);
		},
	});
}

export const singleProcessOptions = (
	workspaceId: string,
	processInstanceId: string,
) =>
	queryOptions({
		queryKey: processInstanceKeys.single(workspaceId, processInstanceId),
		queryFn: () =>
			apiGet<IProcessInstance>(
				`ws/${workspaceId}/process-instances/${processInstanceId}`,
			),
	});

export function useSingleProcessInstance(processInstanceId: string) {
	const workspaceId = useWorkspaceId();
	if (!workspaceId) {
		throw new Error("useProcessInstance: workspaceId is not defined");
	}
	return useQuery(singleProcessOptions(workspaceId, processInstanceId));
	// onError: (error) => {
	// 	if (error.response?.status === 404) {
	// 		toast.info(
	// 			intl.formatMessage({ id: "PROCESS_NOT_FOUND_REDIRECTING" }),
	// 		);
	// 		navigate({ to: "/" });
	// 	}
	// },
}

export function useProcessInstancesFromPortfolio(
	{
		streamId,
		page,
		pageSize,
		search,
		sortBy,
		sortOrder,
		from,
		to,
	}: BaseProcessParams & { from: string; to: string },
	enabled = true,
): UseQueryResult<IPaginatedResults<IProcessInstance> | undefined, Error> {
	const workspaceId = useWorkspaceId();
	const { activeOrganization: organizationId } = useOrganizationData();
	if (!workspaceId) {
		throw new Error(
			"useProcessInstancesFromPortfolio: workspaceId is not defined",
		);
	}

	const filters = {
		search,
		page,
		pageSize,
		sortBy,
		sortOrder,
		from,
		to,
	};
	return useQuery({
		queryKey: processInstanceKeys.params(
			workspaceId,
			processStreamsInstances,
			streamId as string,
			filters,
		),
		queryFn: () =>
			apiGet<IPaginatedResults<IProcessInstance> | undefined>(
				`ws/${workspaceId}/portfolios/${streamId}/process-instances`,
				{
					params: {
						organizationId,
						from,
						to,
						page,
						pageSize,
						search,
						sortBy,
						sortOrder,
						states: 2,
					},
				},
			),
		enabled,
	});
}

export function useProcessInstancesFromBatch(
	params: BaseProcessParams & { batchId: string },
): UseQueryResult<IPaginatedResults<IProcessInstance> | undefined, Error> {
	const workspaceId = useWorkspaceId();
	const { activeOrganization } = useOrganizationData();

	const { batchId, streamId, ...filtered } = params;
	return useQuery({
		queryKey: processInstanceKeys.processInstancesFromBatch(
			workspaceId,
			batchId,
			activeOrganization,
			filtered,
		),

		queryFn: () =>
			apiGet<IPaginatedResults<IProcessInstance> | undefined>(
				`/ws/${workspaceId}/routines/${streamId}/batches/${batchId}/process-instances`,
				{
					params: {
						...filtered,
						organizationId: activeOrganization,
						states: 2,
					},
				},
			),
		placeholderData: keepPreviousData,
	});
}

export function useProcessInstancesFromCluster(
	params: BaseProcessParams & { clusterId: string },
): UseQueryResult<IPaginatedResults<IProcessInstance> | undefined, Error> {
	const workspaceId = useWorkspaceId();
	const { activeOrganization } = useOrganizationData();

	const { streamId, clusterId, ...filtered } = params;
	return useQuery({
		queryKey: processInstanceKeys.processInstancesFromCluster(
			workspaceId,
			streamId,
			clusterId,
			activeOrganization,
			filtered,
		),

		queryFn: () =>
			apiGet<IPaginatedResults<IProcessInstance> | undefined>(
				`/ws/${workspaceId}/clustered/${streamId}/clusters/${clusterId}/process-instances`,
				{
					params: {
						...filtered,
						organizationId: activeOrganization,
						states: 2,
					},
				},
			),
		placeholderData: keepPreviousData,
	});
}

interface UpdateStartDateArgs extends BaseParams {
	date: Date;
}

const updateStartDate = async ({
	workspaceId,
	processInstanceId,
	date,
}: UpdateStartDateArgs): Promise<Partial<IProcessInstance>> => {
	const res = await apiPut(
		`ws/${workspaceId}/process-instances/${processInstanceId}/start-date`,
		{
			date: date.toISOString().slice(0, 10),
		},
	);
	return res.data as Partial<IProcessInstance>;
};

export function useUpdateStartDate(
	processInstanceId: string,
): UseMutationResult<Partial<IProcessInstance>, Error, Date> {
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	const intl = useIntl();
	if (!workspaceId) {
		throw new Error("useUpdateStartDate: workspaceId is not defined");
	}
	return useMutation({
		mutationFn: (date: Date) =>
			updateStartDate({ workspaceId, processInstanceId, date }),

		onError: (error) => {
			toast.error(`Error: ${error.message}`);
		},

		onSuccess: (data) => {
			toast.success(intl.formatMessage({ id: "SUCCESS" }));
			queryClient.setQueryData(
				processInstanceKeys.single(workspaceId, processInstanceId),
				{
					...queryClient.getQueryData<IProcessInstance>(
						processInstanceKeys.single(workspaceId, processInstanceId),
					),
					...data,
				},
			);
			queryClient.invalidateQueries({
				queryKey: processInstanceKeys.stages(workspaceId, processInstanceId),
			});
		},
	});
}

const Behavior = z.enum(["silent", "cancelAll", "resolveAll"]);

export function useCloseProcessInstance(): UseMutationResult<
	AxiosResponse<IProcessInstance> | { data: undefined },
	AxiosError<ExpectedErrorResponseType>,
	{ processInstanceId: string; type: UserSelectedProcessState }
> {
	const closeTypeMap = {
		open: { path: "open", params: {} },
		close: {
			path: "close",
			params: {
				behavior: Behavior.enum.silent,
			},
		},
		closeAndDone: {
			path: "/close",
			params: {
				behavior: Behavior.enum.resolveAll,
			},
		},
		closeAndCancel: {
			path: "close",
			params: {
				behavior: Behavior.enum.cancelAll,
			},
		},
	};
	const workspaceId = useWorkspaceId();
	const queryClient = useQueryClient();
	if (!workspaceId) {
		throw new Error("useUpdateStartDate: workspaceId is not defined");
	}
	return useMutation({
		mutationFn: ({ processInstanceId, type }) =>
			apiPut(
				`ws/${workspaceId}/process-instances/${processInstanceId}/${closeTypeMap[type].path}`,
				closeTypeMap[type].params,
			),

		onError: (error) => {
			toast.error(`Error: ${error?.response?.data?.message}`);
		},

		onSuccess: () => {
			queryClient.invalidateQueries({
				queryKey: processInstanceKeys.all(workspaceId),
			});
			queryClient.invalidateQueries({
				queryKey: [workspaceId, "single-step-instance"],
			});
		},
	});
}

export function useProcessResources(processInstanceId: string) {
	const workspaceId = useWorkspaceId();
	return useQuery({
		queryKey: [workspaceId, "processInstances", processInstanceId, "resources"],
		queryFn: () =>
			apiGet<ResourcesArray>(
				`ws/${workspaceId}/process-instances/${processInstanceId}/resources`,
			),
	});
}
