import clsx from "clsx";
import type React from "react";
import styles from "./ColumnList.module.scss";

export interface IList {
	title: string | JSX.Element;
	value?: number | string | JSX.Element;
	id: string;
}

export const ColumnList: React.FC<{
	list: IList[];
}> = ({ list }) => {
	const row1 = list
		.filter((item) => item.value)
		.map((item) => {
			const textClass = typeof item.value === "string" ? "truncate" : "";
			return (
				<div className="w-full flex flex-col" key={`column-${item.id}`}>
					<div className="flex flex-row gap-2 items-center min-w-125px">
						<span
							className={styles.title}
							title={typeof item.title === "string" ? item.title : ""}
						>
							{item.title}
						</span>
					</div>
					<div
						title={typeof item.value === "string" ? item.value : ""}
						className={clsx(
							"font-medium text-base overflow-visible",
							textClass,
							styles.dateWrapper,
						)}
					>
						<span>{item.value}</span>
					</div>
				</div>
			);
		});

	return (
		<div className="column-list flex align-items-strech flex-wrap gap-4">
			{row1}
		</div>
	);
};
