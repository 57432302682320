import clsx from "clsx";
import type React from "react";
import { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import sanitizeHtml from "sanitize-html";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Avatar } from "@metronome/components/Avatar";
import Button from "@metronome/components/Button";
import type { NoteSchema } from "@metronome/types/Note";
import { NoteType } from "@metronome/types/NoteType";
import { getInitials } from "@metronome/utils/formatText";

import formatDate, {
	formatDateDistanceToNowStrict,
} from "@metronome/utils/formatDate";

import type { BaseParams } from "@metronome/api/useNotes";
import {
	useNoteMutation,
	useUpdateNoteMutation,
} from "@metronome/api/useNotes";
import useSelf from "@metronome/api/useSelf";
import LoadingMetronome from "@metronome/components/LoadingMetronome";
import { EditNote, WriteNote } from "@metronome/features/Note";
import type { IUser } from "@metronome/types/User";
import { getUserUri } from "@metronome/utils/user";

import DisplayDoerType from "@metronome/components/DisplayDoerType";
import { getDoerName, type UserDoer } from "@metronome/types/Doer";
import { UserName } from "@metronome/components/UserName";

const isANoteUpdate = (note: object): note is NoteSchema => "id" in note;

const renderSwitch = (noteType: NoteType): JSX.Element | undefined => {
	switch (noteType) {
		case NoteType.enum.flagAdded:
			return (
				<div>
					<FontAwesomeIcon
						icon={["fas", "flag"]}
						style={{ color: "var(--text-danger)" }}
						className="px-2"
					/>
					<span className="text-base mb-0 mt-4 text-center">
						<FormattedMessage id="NOTES.FLAG_ADDED" />
					</span>
				</div>
			);
		case NoteType.enum.flagRemoved:
			return (
				<div>
					<FontAwesomeIcon
						icon={["fas", "flag"]}
						color="grey"
						className="px-2"
					/>
					<span className="text-base mb-0 mt-4 text-center">
						<FormattedMessage id="NOTES.FLAG_REMOVED" />
					</span>
				</div>
			);
		default:
			return undefined;
	}
};

type SingleEditableNoteProps = {
	note: NoteSchema;
	userId: string;
	context: BaseParams["context"];
	contextId: string;
	user?: IUser;
};
const SingleEditableNote: React.FC<SingleEditableNoteProps> = ({
	note,
	userId,
	user,
	context,
	contextId,
}) => {
	const intl = useIntl();
	const [isEditing, setIsEditing] = useState(false);
	const escapedNote = sanitizeHtml(note.content);

	const [currentNote, setCurrentNote] = useState<
		NoteSchema | { content: string }
	>({ content: "" });

	const updateNote = useUpdateNoteMutation();

	const handleChange = (content: string): void => {
		setCurrentNote({ ...(currentNote as NoteSchema), content });
	};

	const handleNoteClick = (selectedNote: NoteSchema): void => {
		if ((selectedNote.createdBy as UserDoer).userId === userId) {
			setCurrentNote(selectedNote);
		}
	};

	const handleSubmit = (): void => {
		if (isANoteUpdate(currentNote)) {
			updateNote
				.mutateAsync({
					content: currentNote.content,
					context,
					contextId,
					noteId: currentNote.id,
				})
				.then(() => {
					setCurrentNote({ content: "" });
				})
				.finally(() => {
					setIsEditing(false);
				});
		}
	};

	return (
		<div key={`note-${note.id}`} className="mb-4">
			{renderSwitch(note.noteType)}
			<div className={clsx("flex gap-2")}>
				<Avatar
					src={getUserUri(user)}
					alt={getInitials(user?.firstName, user?.lastName)}
				/>
				<div>
					<div className="flex flex-row gap-2 items-center">
						<span className="font-bold">
							<UserName userId={(note.createdBy as UserDoer).userId} />
						</span>
						<span
							className="text-base text-slate-600"
							title={formatDate(note.createdOn, "PPpp", {
								locale: intl.locale,
							})}
						>
							{formatDateDistanceToNowStrict(new Date(note.createdOn), {
								addSuffix: true,
								locale: intl.locale,
							})}
						</span>
					</div>
					{isEditing ? (
						<EditNote
							onSubmit={handleSubmit}
							onChange={handleChange}
							onCancel={() => setIsEditing(false)}
							initialIsEditing={isEditing}
							content={currentNote.content}
						/>
					) : (
						<>
							<div
								className="mt-1 whitespace-pre-line"
								// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
								dangerouslySetInnerHTML={{ __html: escapedNote }}
							/>
							<Button
								onClick={() => {
									handleNoteClick(note);
									setIsEditing(true);
								}}
								appearance="default"
							>
								<FormattedMessage id="EDIT" />
							</Button>
						</>
					)}
				</div>
			</div>
		</div>
	);
};

type SingleNoteProps = {
	note: NoteSchema;
};

const SingleNote: React.FC<SingleNoteProps> = ({ note }) => {
	const intl = useIntl();
	const escapedNote = sanitizeHtml(note.content);

	return (
		<div key={`note-${note.id}`} className="mb-4">
			{renderSwitch(note.noteType)}
			<div className={clsx("flex gap-2")}>
				<DisplayDoerType doer={note.createdBy} />
				<div>
					<div className="flex flex-row gap-2 items-center">
						<span className="font-bold">{getDoerName(note.createdBy)}</span>
						<span
							className="text-base text-slate-600"
							title={formatDate(note.createdOn, "PPpp", {
								locale: intl.locale,
							})}
						>
							{formatDateDistanceToNowStrict(new Date(note.createdOn), {
								addSuffix: true,
								locale: intl.locale,
							})}
						</span>
					</div>
					<div
						className="mt-1 whitespace-pre-line"
						// biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
						dangerouslySetInnerHTML={{ __html: escapedNote }}
					/>
				</div>
			</div>
		</div>
	);
};

const NoCommentsYet: React.FC = () => (
	<div className="py-4 flex justify-center">
		<FormattedMessage id="NO_COMMENTS_YET" />
	</div>
);

type RenderAllNotesProps = {
	notes: NoteSchema[];
	user: IUser;
	userId: string;
	context: BaseParams["context"];
	contextId: string;
};
const RenderAllNotes: React.FC<RenderAllNotesProps> = ({
	notes,
	user,
	userId,
	context,
	contextId,
}) => {
	const noteElements = notes?.map((note) => {
		if (note.createdBy && (note?.createdBy as UserDoer).userId === userId)
			return (
				<SingleEditableNote
					key={note.id}
					context={context}
					contextId={contextId}
					userId={userId}
					user={user}
					note={note}
				/>
			);
		return <SingleNote key={note.id} note={note} />;
	});

	return <div>{notes?.length ? noteElements : <NoCommentsYet />}</div>;
};

type WriteNoteWrapperProps = {
	context: BaseParams["context"];
	contextId: string;
};
const WriteNoteWrapper: React.FC<WriteNoteWrapperProps> = ({
	context,
	contextId,
}) => {
	const [currentNote, setCurrentNote] = useState<
		NoteSchema | { content: string }
	>({ content: "" });

	const createNote = useNoteMutation();

	const handleChange = (content: string): void => {
		setCurrentNote({ ...(currentNote as NoteSchema), content });
	};

	const handleSubmit = (): void => {
		createNote
			.mutateAsync({
				content: currentNote.content,
				noteType: NoteType.enum.default,
				context,
				id: contextId,
			})
			.then(() => {
				setCurrentNote({ content: "" });
			})
			.finally(() => null);
	};
	return (
		<WriteNote
			onSubmit={handleSubmit}
			onChange={handleChange}
			content={currentNote.content}
		/>
	);
};

export const NotesTemplate: React.FC<{
	notes: NoteSchema[];
	userId: string;
	context: BaseParams["context"];
	contextId: string;
}> = ({ notes, userId, context, contextId }) => {
	const { data: user } = useSelf();

	if (!user) {
		return <LoadingMetronome />;
	}

	return (
		<div className="flex flex-col basis-full max-w-md lg:max-w-lg">
			<WriteNoteWrapper context={context} contextId={contextId} />
			<div className="flex flex-col">
				<RenderAllNotes
					context={context}
					contextId={contextId}
					notes={notes}
					user={user}
					userId={userId}
				/>
			</div>
		</div>
	);
};
