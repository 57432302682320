import {
	Dialog,
	DialogContent,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from "@metronome/components/ui/dialog";
import { type FC, useState } from "react";
import { AssignmentModal } from "./Modals/AssignmentModal";
import { Button } from "@metronome/components/ui/button";
import { FormattedMessage } from "react-intl";
import { PlusIcon } from "@radix-ui/react-icons";
import type { RoleAndPartyAssignments } from "@metronome/types/PartyAssignment";
import { useIsMobile } from "@metronome/hooks/useResponsive";

type CreateAssignmentProps = {
	contextId: string;
	roleId: string;
	context: "step-instances" | "process-instances";
	label?: string;
	applicationLevel: RoleAndPartyAssignments["applicationLevel"];
	nbOfAssignees: number;
	targetCount: number;
};

export const CreateAssignment: FC<CreateAssignmentProps> = ({
	context,
	contextId,
	roleId,
	label,
	applicationLevel,
	nbOfAssignees,
	targetCount,
}) => {
	const isMobile = useIsMobile();
	const [open, setOpen] = useState(false);
	return (
		<Dialog open={open} onOpenChange={setOpen}>
			<DialogTrigger asChild>
				<Button size="icon-sm" variant="outline">
					<PlusIcon />
					{label}
				</Button>
			</DialogTrigger>
			<DialogContent>
				<DialogHeader>
					<DialogTitle>
						<FormattedMessage id={"ADD_ASSIGNEE"} />
					</DialogTitle>
				</DialogHeader>
				<AssignmentModal
					contextId={contextId}
					roleId={roleId}
					context={context}
					onSuccess={() => setOpen(false)}
					applicationLevel={applicationLevel}
				/>
			</DialogContent>
		</Dialog>
	);
};
