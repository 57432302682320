import * as React from "react";

import { Button } from "@metronome/components/ui/button";
import { Calendar } from "@metronome/components/ui/calendar";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@metronome/components/ui/popover";
import { cn } from "@metronome/utils";
import { CalendarIcon } from "@radix-ui/react-icons";
import { FormattedMessage, useIntl } from "react-intl";
import type { DateRange } from "react-day-picker";

type Props = {
	range: DateRange;
	onValidate?: (date: DateRange) => void;
};

export function DateRangePicker({ range, onValidate }: Props): JSX.Element {
	const intl = useIntl();
	const onSelect = React.useCallback(
		({ from, to }: DateRange) => {
			if (!from || !to) return;
			onValidate?.({ from, to });
		},
		[onValidate],
	);

	const displayDate =
		range.from && range.to
			? intl.formatDateTimeRange(range.from, range.to, {})
			: undefined;

	return (
		<Popover modal>
			<PopoverTrigger asChild>
				<Button
					variant={"outline"}
					className={cn(
						"justify-start text-left font-normal",
						!displayDate && "text-muted-foreground",
					)}
				>
					<CalendarIcon className="mr-1 h-4 w-4" />
					{displayDate ? (
						<span>{displayDate}</span>
					) : (
						<span>
							<FormattedMessage id="PICK_A_DATE" />
						</span>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-auto p-0">
				<Calendar
					mode="range"
					selected={{
						from: range.from,
						to: range.to,
					}}
					onSelect={onSelect}
					defaultMonth={range.from}
					required={true}
				/>
			</PopoverContent>
		</Popover>
	);
}
