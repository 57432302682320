import { APPEARANCES } from "@metronome/constants/theme";
import type { ProcessState } from "@metronome/types/ProcessState";
import { EResolution } from "@metronome/types/Resolution";
import { ETimeliness } from "@metronome/types/Timeliness";

export type HexColorCouple = {
	background: string;
	foreground: string;
};

export const getHexColorFromStatusClass = (
	resolution: EResolution,
	timeliness?: ETimeliness,
): HexColorCouple => {
	if (resolution === EResolution.enum.done) {
		return {
			background: "#E3FCEF",
			foreground: "#006644",
		};
	}
	if (resolution === EResolution.enum.cancelled) {
		return {
			background: "#DFE1E6",
			foreground: "#42526E",
		};
	}

	if (timeliness === ETimeliness.enum.late) {
		return {
			background: "#ffebe6",
			foreground: "#bf2600",
		};
	}

	if (timeliness === ETimeliness.enum.onTrack) {
		return {
			background: "#E3FCEF",
			foreground: "#006644",
		};
	}

	return {
		background: "#DEEBFF",
		foreground: "#0747A6",
	};
};

export const getResolutionApperance = (
	resolution: EResolution,
): APPEARANCES => {
	if (resolution === EResolution.enum.notStarted) {
		return APPEARANCES.warning;
	}

	if (resolution === EResolution.enum.inProgress) {
		return APPEARANCES.success;
	}

	if (resolution === EResolution.enum.onHold) {
		return APPEARANCES.danger;
	}

	if (resolution === EResolution.enum.done) {
		return APPEARANCES.default;
	}

	return APPEARANCES.default;
};

export const getStateApperance = (state: ProcessState): APPEARANCES => {
	if (state === "closed") {
		return APPEARANCES.default;
	}

	if (state === "active") {
		return APPEARANCES.success;
	}

	if (state === "ready") {
		return APPEARANCES.warning;
	}

	return APPEARANCES.default;
};
