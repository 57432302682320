import { useUpdateStepInstanceSchedule } from "@metronome/api/useStepInstance";
import { Button } from "@metronome/components/ui/button";
import { Label } from "@metronome/components/ui/label";
import { TimePickerInput } from "@metronome/components/ui/timePickerInput";
import { ArrowRightIcon, ClockIcon } from "@radix-ui/react-icons";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@metronome/components/ui/popover";
import { useCallback, useState } from "react";
import { FormattedDateTimeRange, FormattedMessage } from "react-intl";
import type { ISchedule } from "@metronome/types/StepInstance";
import {
	Tabs,
	TabsList,
	TabsContent,
	TabsTrigger,
} from "@metronome/components/ui/tabs";
import { Calendar } from "@metronome/components/ui/calendar";
import type { Duration } from "duration-fns";
import { getMinutes, isThisYear } from "date-fns";
import type {
	DateRange,
	DayPickerProps,
	OnSelectHandler,
} from "react-day-picker";
import { useFormatTimeRangeToString } from "@metronome/utils/formatDuration";

type CustomItemScheduleProps = {
	since: string | number | Date;
	till: string | number | Date;
	stepId: string;
	schedule: ISchedule;
	children?: React.ReactNode;
	processId?: string;
	childrenAsChild?: boolean;
	mode?: DayPickerProps["mode"];
};
export const CustomItemSchedule: React.FC<CustomItemScheduleProps> = ({
	since,
	till,
	stepId,
	schedule,
	children,
	processId,
	childrenAsChild,
	mode = "single",
}) => {
	const [open, setOpen] = useState(false);
	const { scheduledByRegulator } = schedule;
	const sinceDate = new Date(since);
	const [startDate, setStartDate] = useState<Date>(sinceDate);
	const tillDate = new Date(till);
	const [endDate, setEndDate] = useState<Date>(tillDate);
	const { duration, durationAsString, error } = useFormatTimeRangeToString(
		startDate,
		endDate,
	);
	const { mutate } = useUpdateStepInstanceSchedule(stepId, processId);
	const onSave = useCallback(
		(plannedDateTime: Date, plannedDuration: Duration) => {
			mutate({ plannedDateTime, plannedDuration });
			setOpen(false);
		},
		[mutate],
	);

	const onSelectRange: OnSelectHandler<DateRange> = useCallback(
		(dateRange: { from: Date | undefined; to?: Date }) => {
			if (!dateRange.from) return;

			const fromWithTime = new Date(
				dateRange.from.setHours(sinceDate.getHours(), sinceDate.getMinutes()),
			);
			setStartDate(fromWithTime);

			if (!dateRange.to) return;
			const toWithTime = new Date(
				dateRange.to.setHours(tillDate.getHours(), tillDate.getMinutes()),
			);
			setEndDate(toWithTime);
		},
		[sinceDate, tillDate],
	);
	const onSelectDate = useCallback((date: Date | undefined) => {
		if (!date) return;

		setStartDate(date);
		setEndDate(date);
	}, []);

	const setEndDateFn = useCallback(
		(date: Date | undefined) => setEndDate(date as Date),
		[],
	);

	const setStartDateFn = useCallback(
		(date: Date | undefined) => setStartDate(date as Date),
		[],
	);

	const reInitialize = useCallback(() => {
		mutate({
			plannedDateTime: schedule.scheduleLowerBand,
			plannedDuration: schedule.estimatedDuration ?? schedule.plannedDuration,
		});
	}, [
		mutate,
		schedule.estimatedDuration,
		schedule.plannedDuration,
		schedule.scheduleLowerBand,
	]);
	return (
		<Popover open={open} onOpenChange={setOpen}>
			<PopoverTrigger
				className="hover:underline group"
				asChild={childrenAsChild}
			>
				{children ?? (
					<FormattedDateTimeRange
						day="numeric"
						month="numeric"
						hour="numeric"
						minute={getMinutes(since) === 0 ? undefined : "numeric"}
						year={isThisYear(since) ? undefined : "numeric"}
						from={new Date(since)}
						to={new Date(till)}
					/>
				)}
			</PopoverTrigger>
			<PopoverContent id="popovercontenthere" className="!p-2">
				<Tabs defaultValue="date">
					<div className="flex items-center justify-between">
						<TabsList>
							<TabsTrigger value="date">
								<FormattedMessage id="DATE" />
							</TabsTrigger>
							<TabsTrigger value="time">
								<FormattedMessage id="TIME" />
							</TabsTrigger>
						</TabsList>
						<Button
							disabled={!!error && mode === "range"}
							onClick={() => onSave(startDate, duration)}
							className="ms-auto"
						>
							<FormattedMessage id="SAVE" />
						</Button>
					</div>
					<TabsContent value="time">
						{!!scheduledByRegulator && (
							<div className=" px-1 mb-2 flex items-center justify-between borderrounded">
								<span className="text-orange-600">
									<FormattedMessage id="MODIFIED_BY_REGULATOR" />
								</span>
								<Button
									onClick={reInitialize}
									size="sm"
									variant="outlineDestructive"
								>
									<FormattedMessage id="REINITIALIZE" />
								</Button>
							</div>
						)}
						<div className="flex-row items-center flex gap-4">
							<div>
								<Label className="font-bold">
									<FormattedMessage id="PLANNED_HOUR_START" />
								</Label>
								<TimePickerInput date={startDate} setDate={setStartDateFn} />
							</div>
							<ArrowRightIcon />
							<div>
								<Label className="font-bold">
									<FormattedMessage id="PLANNED_HOUR_END" />
								</Label>
								<TimePickerInput date={endDate} setDate={setEndDateFn} />
							</div>
						</div>
						<div className="flex items-center pt-4">
							{error ? (
								<span className="text-xs text-red-500">
									<FormattedMessage id={error} />
								</span>
							) : (
								<span className="text-xs">
									<ClockIcon className="inline align-sub" /> {durationAsString}
								</span>
							)}
						</div>
					</TabsContent>
					<TabsContent value="date" className="flex flex-col">
						<Calendar
							mode={mode}
							selected={
								mode === "range"
									? {
											from: startDate,
											to: endDate,
										}
									: startDate
							}
							hideWeekdays={true}
							onSelect={mode === "range" ? onSelectRange : onSelectDate}
							defaultMonth={startDate}
							required={false}
						/>
					</TabsContent>
				</Tabs>
			</PopoverContent>
		</Popover>
	);
};
