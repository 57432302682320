import { Table, TableBody } from "@metronome/components/ui/table";
import { createFileRoute, useParams } from "@tanstack/react-router";
import { ProcessAssignments } from "../-ProcessAssignments";
import { ProcessResources } from "../-ProcessResources";
import {
	Card,
	CardContent,
	CardDescription,
	CardHeader,
	CardTitle,
} from "@metronome/components/ui/card";
import { StageListSwitch } from "@metronome/components/StageList/StageListSwitch";
import {
	singleProcessOptions,
	useStages,
} from "@metronome/api/useProcessInstance";
import { FormattedMessage } from "react-intl";
import { useQuery } from "@tanstack/react-query";
import { ProgressBar } from "@metronome/components/Progress";
import { getHexColorFromStatusClass } from "@metronome/utils/status";
import { getTimelinessFromTimeline } from "@metronome/utils/timeliness";

export const Route = createFileRoute(
	"/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/summary",
)({
	component: RouteComponent,
});

function RouteComponent() {
	const { processId, workspaceId } = useParams({
		from: "/$workspaceId/stream/$streamId/process/$processId/gates-and-steps/summary",
	});
	const { data: gates } = useStages(processId);
	const { data: processInstance } = useQuery(
		singleProcessOptions(workspaceId, processId),
	);
	return (
		<div className="p-1 grid grid-cols-[repeat(3,_minmax(150px,_350px))] gap-4">
			<Card className="max-w-[350px]">
				<CardHeader>
					<CardTitle>
						<FormattedMessage id="ROLES" />
					</CardTitle>
					<CardDescription>Rôles et personnes</CardDescription>
				</CardHeader>
				<CardContent>
					<ProcessAssignments processId={processId} />
				</CardContent>
			</Card>
			<Card className="max-w-[350px]">
				<CardHeader>
					<CardTitle>
						<FormattedMessage id="RESOURCES" />
					</CardTitle>
					<CardDescription>Communes à toutes les étapes</CardDescription>
				</CardHeader>
				<CardContent>
					<Table className="bg-white w-fit rounded">
						<TableBody>
							<ProcessResources processId={processId} />
						</TableBody>
					</Table>
				</CardContent>
			</Card>
			<Card className="max-w-[350px]">
				<CardHeader>
					<CardTitle>
						<FormattedMessage id="STEPS" />
					</CardTitle>
					<CardDescription>Les étapes du process</CardDescription>
				</CardHeader>
				<CardContent>
					{!!processInstance && (
						<div className="flex items-center gap-2 px-2">
							<ProgressBar
								progress={processInstance.progress}
								colorCouple={getHexColorFromStatusClass(
									processInstance.resolution,
									getTimelinessFromTimeline(processInstance.schedule),
								)}
							/>
							{processInstance?.progress ?? 0}%
						</div>
					)}
					{gates?.length && <StageListSwitch gates={gates} />}
				</CardContent>
			</Card>
		</div>
	);
}
