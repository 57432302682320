import { useCreateAssignments } from "@metronome/api/useAssignments";
import { useGetPartiesFiltersInfinite } from "@metronome/api/useFilters";
import { ComboboxPaginated } from "@metronome/components/ComboBoxPaginated";
import { Button } from "@metronome/components/ui/button";
import type { RoleAndPartyAssignments } from "@metronome/types/PartyAssignment";

import { useCallback, useState } from "react";
import type { MouseEvent } from "react";
import { useIntl } from "react-intl";

type Option = {
	label: string;
	value: string;
	icon?: string;
};

export const AssignmentModal: React.FC<{
	contextId: string;
	roleId: string;
	context: "step-instances" | "process-instances";
	onSuccess: () => void;
	applicationLevel: RoleAndPartyAssignments["applicationLevel"];
}> = ({ roleId, contextId, context, onSuccess, applicationLevel }) => {
	const intl = useIntl();
	const [search, setSearch] = useState("");
	const [partyId, setPartyId] = useState("");

	const { mutate: updateAssignment, isPending } = useCreateAssignments(
		context,
		contextId,
		applicationLevel,
		onSuccess,
	);

	const {
		data: parties,
		fetchNextPage,
		hasNextPage,
	} = useGetPartiesFiltersInfinite(search);

	const partiesToSelect = parties?.pages
		.flatMap((page) => page?.results)
		.map((party) => {
			return {
				label: party?.name,
				value: party?.id,
				icon: party?.profilePictureUri,
			};
		}) as Option[];

	const onClickSaveAssignments = useCallback(
		(e: MouseEvent<HTMLButtonElement>) => {
			e.preventDefault();
			updateAssignment({ partyId, roleId });
		},
		[updateAssignment, partyId, roleId],
	);

	return (
		<form key={context + contextId} className="flex flex-col gap-4">
			<section className="flex flex-col gap-2">
				<ComboboxPaginated
					setSearchValue={setSearch}
					value={partyId}
					setValue={setPartyId}
					items={partiesToSelect}
					fetchNextPage={fetchNextPage}
					hasNextPage={hasNextPage}
				/>
			</section>
			<div className="flex flex-row justify-end">
				<Button
					disabled={isPending || !roleId || !partyId}
					onClick={onClickSaveAssignments}
				>
					{isPending
						? intl.formatMessage({ id: "LOADING" })
						: intl.formatMessage({
								id: "SAVE",
							})}
				</Button>
			</div>
		</form>
	);
};
