import StepInstancePage from "@metronome/features/step-instance/StepInstancePage";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { createFileRoute, Link } from "@tanstack/react-router";
import { FormattedMessage } from "react-intl";

export const Route = createFileRoute(
	"/$workspaceId/stream/$streamId/process/$processId/step/$stepId",
)({
	component: RouteComponent,
});

function RouteComponent() {
	const { stepId } = Route.useParams();
	return (
		<div>
			<Link
				className="p-2 block"
				from="/$workspaceId/stream/$streamId/process/$processId/step/$stepId"
				to="/$workspaceId/stream/$streamId/process/$processId/gates-and-steps"
			>
				<ArrowLeftIcon className="inline me-2" />
				<FormattedMessage id="BACK" />
			</Link>
			<StepInstancePage stepId={stepId} />
		</div>
	);
}
