import { useUpdateResolution } from "@metronome/api/useStepInstance";
import { ConfirmationModal } from "@metronome/components/ConfirmationModal";
import {
	SelectTrigger,
	SelectValue,
	Select,
	SelectItem,
	SelectContent,
} from "@metronome/components/ui/select";
import type { ProcessState } from "@metronome/types/ProcessState";
import {
	EEventResolution,
	EMilestoneResolution,
	EResolution,
} from "@metronome/types/Resolution";
import type { IStepInstance } from "@metronome/types/StepInstance";
import { isProcessActive } from "@metronome/utils/isProcessActive";
import { UpdateIcon } from "@radix-ui/react-icons";
import axios from "axios";
import clsx from "clsx";
import React, { useState } from "react";
import { useIntl } from "react-intl";

const getAvailableOptions = (type: IStepInstance["type"]): EResolution[] => {
	switch (type) {
		case "task":
			return EResolution.options;
		case "event":
			return EEventResolution.options;
		case "milestone":
			return EMilestoneResolution.options;
		case "hook":
			return EMilestoneResolution.options;
		default:
			return EResolution.options;
	}
};

export const UpdateResolution = ({
	type,
	resolution,
	stepInstanceId,
	processState,
	processId,
}: {
	type: IStepInstance["type"];
	resolution: EResolution;
	stepInstanceId: string;
	processId: string;
	processState?: ProcessState;
}): JSX.Element => {
	const intl = useIntl();
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [selectedAction, setSelectedAction] = useState<EResolution | undefined>(
		resolution,
	);

	const isFromAnActiveProcess = processState
		? isProcessActive(processState)
		: true; // for now let's assume the process is active if we don't have access to its state :D

	const {
		mutateAsync: updateResolution,
		mutate: updateResolutionSync,
		isPending,
	} = useUpdateResolution();

	const onActionSelected = async (action: EResolution): Promise<void> => {
		setSelectedAction(action);
		try {
			await updateResolution({
				resolution: action,
				isForced: false,
				processInstanceId: processId,
				stepInstanceId: stepInstanceId,
			});
		} catch (e: unknown) {
			if (axios.isAxiosError(e)) {
				if (e.response?.status === 400) setShowConfirmation(true);
			}
		}
	};

	const handleConfirmation = (): void => {
		updateResolutionSync({
			resolution: selectedAction as EResolution,
			isForced: true,
			processInstanceId: processId, // todo: processInstanceId here may be undefined, get it from url instead ?
			stepInstanceId: stepInstanceId,
		});
		setShowConfirmation(false);
	};

	return (
		<React.Fragment key={stepInstanceId}>
			<ConfirmationModal
				loading={isPending}
				show={showConfirmation}
				onClose={() => {
					setShowConfirmation(false);
					setSelectedAction(resolution);
				}}
				onConfirm={handleConfirmation}
				message={intl.formatMessage(
					{ id: "RESOLUTION.CONFIRM_MESSAGE" },
					{
						resolution: intl.formatMessage({
							id: `RESOLUTION.${type}.${selectedAction}`,
						}),
					},
				)}
			/>
			<Select
				key={stepInstanceId}
				disabled={isPending}
				onValueChange={(action: EResolution) => {
					onActionSelected(action);
				}}
				value={resolution}
			>
				<SelectTrigger
					disabled={!isFromAnActiveProcess}
					className={clsx({
						"w-[120px]": true,
						"bg-slate-200": resolution === EResolution.enum.notStarted,
						"bg-primary text-white":
							resolution === EResolution.enum.inProgress ||
							resolution === EResolution.enum.onHold,
						"bg-green-600 text-white": resolution === EResolution.enum.done,
					})}
				>
					<SelectValue
						placeholder={intl.formatMessage({
							id: "NO_VIEW_SELECTED",
						})}
					/>
					{!!isPending && (
						<UpdateIcon className="inline-block ms-1 me-1 [vertical-align:-0.125em] animate-spin" />
					)}
				</SelectTrigger>
				<SelectContent>
					{getAvailableOptions(type).map((action) => (
						<SelectItem key={action} value={action}>
							{intl.formatMessage({
								id: `RESOLUTION.${type}.${action}`,
							})}
						</SelectItem>
					))}
				</SelectContent>
			</Select>
		</React.Fragment>
	);
};
