import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as DropdownMenu from "@radix-ui/react-dropdown-menu";
import { OpenInNewWindowIcon } from "@radix-ui/react-icons";
import type React from "react";
import { useIntl } from "react-intl";
import { useQueryClient } from "@tanstack/react-query";

import { apiPut } from "@metronome/api/api";
import { Avatar } from "@metronome/components/Avatar";
import { useIsMobile } from "@metronome/hooks/useResponsive";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type { IUser } from "@metronome/types/User";
import type { IWorkspace } from "@metronome/types/Workspace";
import { toAbsoluteUrl } from "@metronome/utils/assetHelpers";
import { getInitials } from "@metronome/utils/formatText";
import { getUserUri, useName } from "@metronome/utils/user";

import { useLogout } from "../../hooks/useLogout";
import type { Lang } from "../../i18n/LanguageProvider";
import { setLanguage, useLang } from "../../i18n/LanguageProvider";
import { SwitchOrganizationNative } from "../SwitchOrganizationNative";
import myStyles from "./headerUserMenu.module.scss";
import { useNavigate } from "@tanstack/react-router";
import { useOrganizationData } from "@metronome/context/OrganizationData";

const languages = [
	{
		lang: "en-GB",
		flag: toAbsoluteUrl("/media/flags/united-kingdom.svg"),
	},
	{
		lang: "fr",
		flag: toAbsoluteUrl("/media/flags/france.svg"),
	},
];

type Props = {
	user?: IUser;
	workspaces?: IWorkspace[];
};

const HeaderUserMenu: React.FC<Props> = ({ user, workspaces }) => {
	const intl = useIntl();
	const navigate = useNavigate();
	const lang = useLang();
	const logout = useLogout();
	const queryClient = useQueryClient();
	const isMobile = useIsMobile();

	const { setActiveOrganization, setCurrentOrganization } =
		useOrganizationData();

	const workspaceId = useWorkspaceId();

	const handleWorkspaceClick = (workspaceIdArg: string): void => {
		apiPut("self", {
			defaultWorkspaceId: workspaceIdArg,
		})
			.then(() => {
				queryClient.refetchQueries({
					queryKey: ["workspaces"],
				});
				queryClient.invalidateQueries({
					queryKey: ["self"],
				});
				setActiveOrganization(undefined);
				setCurrentOrganization(undefined);
				navigate({
					to: "/$workspaceId/streams",
					params: { workspaceId: workspaceIdArg },
				});
			})
			.catch((error) => {
				// needs discussion ?
				if (error.response.status === 401) {
					navigate({ to: "/error/forbidden-workspace" });
				}
			});
	};

	const handleLanguageChange = (value: string): void => {
		setLanguage(value as Lang);
	};

	const handleLogout = async (): Promise<void> => {
		logout();
	};

	const options = workspaces?.map((workspace) => (
		<DropdownMenu.RadioItem
			key={workspace.id}
			value={workspace.id}
			className={myStyles.itemStyle}
		>
			<DropdownMenu.ItemIndicator className={myStyles.itemIndicatorStyle}>
				<FontAwesomeIcon icon="circle" className={myStyles.itemIndicator} />
			</DropdownMenu.ItemIndicator>
			{workspace.name}
		</DropdownMenu.RadioItem>
	));

	const languagesItems = languages.map((language) => (
		<DropdownMenu.RadioItem
			key={language.lang}
			value={language.lang}
			className={myStyles.itemStyle}
		>
			<DropdownMenu.ItemIndicator className={myStyles.itemIndicatorStyle}>
				<FontAwesomeIcon icon="circle" className={myStyles.itemIndicator} />
			</DropdownMenu.ItemIndicator>
			{intl.formatMessage({
				id: `LANGUAGE.${language?.lang}`,
			})}
		</DropdownMenu.RadioItem>
	));

	return (
		<DropdownMenu.Root>
			<DropdownMenu.Trigger asChild>
				<div className={myStyles.iconButton}>
					<Avatar
						src={getUserUri(user)}
						alt={getInitials(user?.firstName, user?.lastName)}
					/>
				</div>
			</DropdownMenu.Trigger>
			<DropdownMenu.Content
				side="right"
				align="end"
				className={myStyles.contentStyle}
			>
				<DropdownMenu.Label className="text-sm m-2 font-bold text-slate-600">
					{useName(user)}
				</DropdownMenu.Label>
				{!!user?.canAccessStudio && (
					<DropdownMenu.Item className="block text-sm m-2" asChild>
						<a
							href={
								window.VITE_BACKOFFICE_URL ||
								import.meta.env.VITE_BACKOFFICE_URL
							}
							target="_blank"
							rel="noreferrer noopener"
						>
							{intl.formatMessage({
								id: "GO_ORCHESTRATOR",
							})}
							<OpenInNewWindowIcon className="inline ms-2" />
						</a>
					</DropdownMenu.Item>
				)}
				<DropdownMenu.Item className="block text-sm m-2" asChild>
					<a
						href={`${
							window.VITE_OPENID_AUTHORITY ||
							import.meta.env.VITE_OPENID_AUTHORITY
						}/personal-settings`}
						target="_blank"
						rel="noreferrer noopener"
					>
						{intl.formatMessage({
							id: "MY_PROFILE",
						})}
						<OpenInNewWindowIcon className="inline ms-2" />
					</a>
				</DropdownMenu.Item>

				{!!isMobile && (
					<>
						<DropdownMenu.Label className="text-sm m-2 font-bold">
							{intl
								.formatMessage({
									id: "ORGANIZATIONS",
								})
								.toUpperCase()}
						</DropdownMenu.Label>
						<div className="ms-2">
							<SwitchOrganizationNative />
						</div>
						<DropdownMenu.Separator className="m-2 h-px bg-slate-200" />
					</>
				)}

				<DropdownMenu.Label className="text-sm m-2 font-bold">
					{intl
						.formatMessage({
							id: "WORKSPACES",
						})
						.toUpperCase()}
				</DropdownMenu.Label>
				<DropdownMenu.RadioGroup
					value={workspaceId}
					onValueChange={handleWorkspaceClick}
				>
					{options}
				</DropdownMenu.RadioGroup>

				<DropdownMenu.Separator className="m-2 h-px bg-slate-200" />

				<DropdownMenu.Label className="text-sm m-2 font-bold">
					{intl
						.formatMessage({
							id: "LANGUAGE",
						})
						.toUpperCase()}
				</DropdownMenu.Label>
				<DropdownMenu.RadioGroup
					value={lang}
					onValueChange={handleLanguageChange}
				>
					{languagesItems}
				</DropdownMenu.RadioGroup>

				<DropdownMenu.Separator className="m-2 h-px bg-slate-200" />

				<DropdownMenu.Item asChild className={myStyles.itemStyle}>
					<a
						href="https://process-metronome.atlassian.net/servicedesk/customer/portals"
						target="_blank"
						rel="noreferrer noopener"
					>
						{intl.formatMessage({ id: "HELP_CENTER" })}
						<OpenInNewWindowIcon className="ms-2" />
					</a>
				</DropdownMenu.Item>
				<DropdownMenu.Item
					onSelect={() => {
						handleLogout();
					}}
					className={myStyles.itemStyle}
				>
					{intl.formatMessage({ id: "AUTH.LOGOUT" })}
				</DropdownMenu.Item>
			</DropdownMenu.Content>
		</DropdownMenu.Root>
	);
};

export { HeaderUserMenu };
