import LoadingMetronome from "@metronome/components/LoadingMetronome";
import type { IProcessInstance } from "@metronome/types/ProcessInstance";
import { format } from "date-fns";
import { useMemo, useState } from "react";
import { useParams } from "@tanstack/react-router";
import { getTimelinessFromStepInstance } from "@metronome/utils/timeliness";
import { StepsTimeline } from "@metronome/features/StepsTimeline";
import { ErrorBoundary } from "@sentry/react";
import { SchedulerError } from "@metronome/components/SchedulerError";
import { calcTillDate } from "@metronome/utils/planby";
import { CustomChannelItem } from "@metronome/features/planby/CustomChannel";
import type { ProgramWithOmittedUuid } from "@nessprim/planby-pro/dist/Epg/helpers";
import {
	useProcessInstancesFromBatch,
	useProcessInstancesFromPortfolio,
} from "@metronome/api/useProcessInstance";
import type { PaginationState } from "@tanstack/react-table";
import type { IStepInstanceLight } from "@metronome/types/StepInstance";
import type { DateRange } from "react-day-picker";
import { FormattedMessage } from "react-intl";

type ProcessesTimelineProps = {
	processes: IProcessInstance[];
	steps: Array<IStepInstanceLight>;
	workspaceId: string;
	initialDate?: string;
};

export const BatchProcessTimelineLoader: React.FC<{
	streamId: string;
	referenceDate: string;
	batchId: string;
}> = ({ streamId, batchId, referenceDate }) => {
	const { workspaceId } = useParams({ strict: false });
	// todo: handle pagination for the timeline ?
	const [pagination, _setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});

	const { data: processes, isLoading } = useProcessInstancesFromBatch({
		streamId,
		batchId,
		page: pagination.pageIndex + 1,
		pageSize: pagination.pageSize,
	});

	return (
		<div className="bg-white">
			{isLoading && <LoadingMetronome />}

			<ErrorBoundary fallback={<SchedulerError />}>
				{!isLoading && !!workspaceId && !!processes?.results?.length && (
					<ProcessesTimeline
						processes={processes.results}
						steps={processes.results.flatMap((p) => p.stepInstances)}
						workspaceId={workspaceId}
						initialDate={referenceDate}
					/>
				)}
			</ErrorBoundary>
		</div>
	);
};

export const PortfolioProcessTimelineLoader: React.FC<{
	streamId: string;
	range: DateRange;
}> = ({ streamId, range }) => {
	const { workspaceId } = useParams({ strict: false });
	// todo: handle pagination for the timeline
	const [pagination, _setPagination] = useState<PaginationState>({
		pageIndex: 0,
		pageSize: 10,
	});

	const { data: processes, isLoading } = useProcessInstancesFromPortfolio({
		streamId,
		page: pagination.pageIndex + 1,
		pageSize: pagination.pageSize,
		from: range.from?.toISOString().slice(0, 10) as string,
		to: range.to?.toISOString().slice(0, 10) as string,
	});

	return (
		<>
			<div className="bg-white mt-2">
				{isLoading && <LoadingMetronome />}
				<ErrorBoundary fallback={<SchedulerError />}>
					{!isLoading && !!workspaceId && !!processes?.results?.length && (
						<ProcessesTimeline
							processes={processes.results}
							steps={processes.results.flatMap((p) => p.stepInstances)}
							workspaceId={workspaceId}
						/>
					)}
				</ErrorBoundary>
			</div>
			{!isLoading && processes?.results.length === 0 && (
				<FormattedMessage id="NO_DATA_FOUND_FOR_DATE_RANGE" />
			)}
		</>
	);
};

const ProcessesTimeline: React.FC<ProcessesTimelineProps> = ({
	processes,
	steps,
	workspaceId,
	initialDate,
}) => {
	const plannedStart = processes[0].schedule.lowerTimeBand;
	const plannedEnd = processes[processes.length - 1].schedule.upperTimeBand;

	const channels = useMemo(
		() =>
			processes.map((p) => ({
				logo: "",
				uuid: p.id,
				title: p.name,
				progress: p.progress,
				schedule: p.schedule,
				resolution: p.resolution,
				workspaceId: workspaceId,
			})),
		[processes, workspaceId],
	);
	const epg: Array<ProgramWithOmittedUuid> = useMemo(
		() =>
			steps
				?.map((step) => {
					const channelUuid = step.processInstanceId;
					const since = format(
						step.schedule?.plannedAt ?? step.schedule.scheduleLowerBand,
						"yyyy-MM-dd'T'HH:mm:ss",
					);
					const till = format(
						calcTillDate(step.schedule),
						"yyyy-MM-dd'T'HH:mm:ss",
					);
					const timeliness = getTimelinessFromStepInstance(
						step.schedule,
						undefined,
						step.type,
					);

					return {
						workspaceId,
						channelUuid,
						processId: channelUuid,
						schedule: step.schedule,
						id: step.id,
						type: step.type,
						timeliness,
						title: step.name,
						since,
						till,
					};
				})
				.filter((s) => !!s),
		[steps, workspaceId],
	);

	if (epg.length === 0)
		return <span>We could not display any items on the timeline.</span>;
	return (
		<StepsTimeline
			initialPlannedStart={plannedStart}
			initialPlannedEnd={plannedEnd}
			epg={epg}
			channels={channels}
			CustomChannelItem={CustomChannelItem}
			initialDate={initialDate}
		/>
	);
};
