import {
	useDeleteAssignments,
	useProcessAssignments,
	useUpdateAssignments,
} from "@metronome/api/useAssignments";
import SwitchButton from "@metronome/components/Switch";
import { Badge } from "@metronome/components/ui/badge";
import { Button } from "@metronome/components/ui/button";
import {
	Collapsible,
	CollapsibleContent,
	CollapsibleTrigger,
} from "@metronome/components/ui/collapsible";
import { Label } from "@metronome/components/ui/label";
import {
	Popover,
	PopoverContent,
	PopoverTrigger,
} from "@metronome/components/ui/popover";
import { Separator } from "@metronome/components/ui/separator";
import { CreateAssignment } from "@metronome/features/CreateAssignment";
import { PartyStatus } from "@metronome/features/PartyStatus";
import { useIsMobile } from "@metronome/hooks/useResponsive";
import type { IParty } from "@metronome/types/Party";
import type { RoleAndPartyAssignments } from "@metronome/types/PartyAssignment";
import { getInitials } from "@metronome/utils/formatText";
import { ChevronRightIcon, PersonIcon } from "@radix-ui/react-icons";
import { FormattedMessage } from "react-intl";

function useResponsivePartyName() {
	const isMobile = useIsMobile();
	const getName = (party: IParty): string => {
		if (isMobile) return getInitials(party.firstName, party.lastName) as string;
		return `${party.lastName} ${party.firstName}`;
	};
	return {
		getName,
	};
}

type AvailabilityPayload = {
	assignmentId: string;
	applicationLevel: RoleAndPartyAssignments["applicationLevel"];
	active: boolean;
	partyId: string;
	roleId: string;
};

export const ProcessAssignments: React.FC<{ processId: string }> = ({
	processId,
}) => {
	const { getName } = useResponsivePartyName();
	const { data, isLoading } = useProcessAssignments(processId);
	const { mutate: deleteAssignment } = useDeleteAssignments(
		"process-instances",
		processId,
	);

	const { mutate: updateAssignment } = useUpdateAssignments(
		"process-instances",
		processId,
	);

	const onAvailabilityChange = ({
		assignmentId,
		applicationLevel,
		active,
		partyId,
		roleId,
	}: AvailabilityPayload) => {
		updateAssignment({
			assignmentId,
			applicationLevel,
			active,
			partyId,
			roleId,
		});
	};

	if (isLoading) return null;
	return (
		<div className="flex flex-col gap-4">
			{data?.map((d) => (
				<Collapsible defaultOpen key={d.role.id}>
					<CollapsibleTrigger className="flex items-center gap-2 [&>svg]:data-[state=open]:rotate-90">
						<ChevronRightIcon className="inline" />
						<span className="capitalize font-medium">{d.role.name}</span>
						<CreateAssignment
							context="process-instances"
							contextId={processId}
							roleId={d.role.id}
							applicationLevel={d.applicationLevel}
							nbOfAssignees={
								d.assignments.filter((a) => a.status !== "rejected").length
							}
							targetCount={d.targetCount}
						/>
					</CollapsibleTrigger>
					<CollapsibleContent>
						<div className="flex flex-col gap-1 items-start ps-4 pt-2">
							{d.assignments.map(
								({ party, id, origin, ownershipLevel, status }) => (
									<Popover key={party.id}>
										<PopoverTrigger>
											<Badge variant="outline" key={party.id}>
												<PersonIcon className="me-1" />
												<span
													className={
														status === "rejected"
															? "text-slate-500"
															: " font-medium"
													}
												>
													{getName(party)}
												</span>
												<PartyStatus status={status} />
											</Badge>
										</PopoverTrigger>
										<PopoverContent>
											{origin === "regulation" &&
												ownershipLevel === "process" && (
													<>
														<span>
															<FormattedMessage id="ASSIGNMENT_CAN_BE_REMOVED" />{" "}
															:
														</span>
														<Button
															onClick={() =>
																deleteAssignment({
																	assignmentId: id,
																	applicationLevel: d.applicationLevel,
																})
															}
															variant="destructive"
															size="sm"
															className="ms-2"
														>
															<FormattedMessage id="REMOVE" />
														</Button>
														<Separator className="my-2" />
													</>
												)}
											<div className="font-bold">
												{`${party.lastName} ${party.firstName}`}
												<span className="text-primary ms-2">{origin}</span>
												<PartyStatus status={status} />
											</div>
											<div>{party.email}</div>

											<div className="flex items-center space-x-2 mt-2">
												<SwitchButton
													checked={status !== "rejected"}
													onCheckedChange={() =>
														onAvailabilityChange({
															applicationLevel: d.applicationLevel,
															assignmentId: id,
															partyId: party.id,
															roleId: d.role.id,
															active: status === "rejected",
														})
													}
												/>
												<Label>
													<FormattedMessage id="AVAILABLE" />
												</Label>
											</div>
										</PopoverContent>
									</Popover>
								),
							)}
						</div>
					</CollapsibleContent>
				</Collapsible>
			))}
		</div>
	);
};
