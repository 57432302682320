import { useOrganizationData } from "@metronome/context/OrganizationData";
import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type {
	IModelReference,
	IPartyModelReference,
} from "@metronome/types/ModelReference";
import type { IPaginatedResults } from "@metronome/types/PaginatedResponse";
import { useInfiniteQuery, keepPreviousData } from "@tanstack/react-query";
import { apiGet } from "./api";

export function useGetNodesFiltersInfinite(search: string) {
	const workspaceId = useWorkspaceId();

	const { activeOrganization: organizationId } = useOrganizationData();
	return useInfiniteQuery({
		queryKey: [workspaceId, organizationId, "nodeFilters", search],
		queryFn: ({ pageParam }) =>
			apiGet<IPaginatedResults<IModelReference> | undefined>(
				`ws/${workspaceId}/nodes`,
				{
					params: {
						organizationId,
						page: pageParam,
						search: search,
					},
				},
			),
		initialPageParam: 1,
		getNextPageParam: (
			lastPage: IPaginatedResults<IModelReference> | undefined,
		): number | undefined => {
			if (lastPage && lastPage?.page >= lastPage?.totalPages) return undefined;
			return lastPage?.page && lastPage.page + 1;
		},
	});
}

export function useGetPartiesFiltersInfinite(
	search: string,
	enabled?: boolean,
) {
	const workspaceId = useWorkspaceId();

	return useInfiniteQuery({
		queryKey: [workspaceId, "partyFilters", search],
		queryFn: ({ pageParam }) =>
			apiGet<IPaginatedResults<IPartyModelReference> | undefined>(
				`ws/${workspaceId}/parties`,
				{
					params: {
						page: pageParam,
						search: search,
					},
				},
			),
		initialPageParam: 1,
		placeholderData: keepPreviousData,
		getNextPageParam: (lastPage): number | undefined => {
			if (lastPage && lastPage?.page >= lastPage?.totalPages) return undefined;
			return lastPage?.page && lastPage.page + 1;
		},
		enabled: enabled ?? true,
	});
}
