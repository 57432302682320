import { useDeleteMetadata } from "@metronome/api/useMetadata";
import { BlockedIcon } from "@metronome/components/BlockedIcon";
import { Button } from "@metronome/components/ui/button";
import type { Metadata, MetadataValue } from "@metronome/types/Metadata";
import { Cross2Icon } from "@radix-ui/react-icons";
import { Interweave } from "interweave";
import { EmailMatcher, UrlMatcher } from "interweave-autolink";
import React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";

type DisplayMetadataValueProps = {
	metadataValue: MetadataValue;
	definition: Metadata["definition"];
	nodeId: string;
	valueId: string;
	contextId: string;
};
export const DisplayMetadataValue: React.FC<DisplayMetadataValueProps> = ({
	metadataValue,
	definition,
	nodeId,
	valueId,
	contextId,
}) => {
	const { mutate: deleteMetadata } = useDeleteMetadata(
		nodeId,
		valueId,
		contextId,
	);
	return (
		<React.Fragment key={`definition-${definition.id}`}>
			<div className="flex flex-row items-center justify-between gap-1 flex-wrap p-0 bg-white">
				{typeof metadataValue.value === "string" && (
					<Interweave
						content={metadataValue.value}
						matchers={[new UrlMatcher("url"), new EmailMatcher("email")]}
					/>
				)}

				{typeof metadataValue.value === "number" && (
					<FormattedNumber value={metadataValue.value} />
				)}

				{Array.isArray(metadataValue.value) && (
					<span>{metadataValue.value.join(", ")}</span>
				)}

				{!metadataValue && (
					<span className="italic">
						<FormattedMessage id="METADATA_NO_VALUE" />
					</span>
				)}
				{definition.canBeEdited && (
					<Button
						onClick={() => deleteMetadata()}
						variant="ghostDestructive"
						size="icon"
					>
						<Cross2Icon />
					</Button>
				)}
			</div>
		</React.Fragment>
	);
};
