import type { ITimeline } from "@metronome/types/Timeline";
import { ETimeliness } from "@metronome/types/Timeliness";
import { getTimelinessFromTimeline } from "@metronome/utils/timeliness";
import type React from "react";
import { FormattedMessage } from "react-intl";
import { Badge } from "./ui/badge";

type PropTags = {
	timeline: ITimeline;
};

export const TimelinessTag: React.FC<PropTags> = ({ timeline }) => {
	const timeliness = getTimelinessFromTimeline(timeline);

	if (!timeliness) {
		return null;
	}
	return (
		<Badge
			variant={
				timeliness === ETimeliness.enum.late ? "outlineDestructive" : "outline"
			}
		>
			<FormattedMessage id={`TIMELINESS.${timeliness}`} />
		</Badge>
	);
};

type PropLozenges = {
	timeliness: ETimeliness | undefined;
};

export const TimelinessLozenge: React.FC<PropLozenges> = ({ timeliness }) => {
	return (
		<Badge
			variant={
				timeliness === ETimeliness.enum.late ? "outlineDestructive" : "outline"
			}
		>
			<FormattedMessage id={`TIMELINESS.${timeliness}`} />
		</Badge>
	);
};
