import type {ILightBusinessDimensionNode} from "@metronome/types/BusinessDimension";
import type {Context} from "@metronome/types/Context";
import {EditableMetadataValues} from "./EditableMetadataValues";
import {DisplayMetadataValue} from "./DisplayMetadataValues";
import {
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@metronome/components/Accordion";
import {ChevronRightIcon} from "@radix-ui/react-icons";
import {FormattedMessage} from "react-intl";
import {useMetadata} from "@metronome/api/useMetadata";
import {Table, TableBody, TableCell, TableRow} from "@metronome/components/ui/table";
import {BlockedIcon} from "@metronome/components/BlockedIcon";
import {Node} from "@metronome/features/Node";
import {Button} from "@metronome/components/ui/button";
import {Dialog, DialogContent, DialogTrigger} from "@metronome/components/ui/dialog";

type FeaturedMetadataProps = {
    contextId: string;
    context: Context;
    businessDimension: ILightBusinessDimensionNode;
};

export const FeaturedMetadata: React.FC<FeaturedMetadataProps> = ({
                                                                      contextId,
                                                                      context,
                                                                      businessDimension,
                                                                  }) => {
    const {data: stepMetadata, isLoading} = useMetadata(contextId);

    if (isLoading) return null;

    if (!stepMetadata || stepMetadata?.length === 0) return null;

    return (
        <>
            <div className="uppercase text-xs font-semibold mt-2">
                <FormattedMessage id="FEATURED_METADATA"/>
            </div>
            <Table className="bg-white">
                <TableBody>
                    {stepMetadata
                        // Todo : is there a sortBy().thenBy() ?
                        .sort((a, b) => {
                            const node = a.node.name.localeCompare(b.node.name);
                            return node !== 0 ? node : a.definition.name.localeCompare(b.definition.name)
                        })
                        .reduce<JSX.Element[]>((acc, { definition, node, values }, index, array) => {
                            // Check if the node has changed
                            if (index === 0 || array[index - 1].node?.id !== node?.id) {
                                // Insert an empty row for the new node
                                acc.push(
                                    <TableRow key={`empty-${node.id}`} className="bg-muted text-capitalize">
                                        <TableCell colSpan={2} className="font-bold">
                                            {node.name}
                                        </TableCell>
                                    </TableRow>
                                );
                            }

                                acc.push(
                                    <TableRow key={`definition-${node.id}-${definition.id}`}>
                                        <TableCell>
                                            <span className="me-1">{definition.name}</span>
                                            {(!definition.canBeEdited || definition.type === "referenceData") && (
                                                <BlockedIcon
                                                    side="right"
                                                    tooltipMessage="BLOCKED_RESOURCE"
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell className="font-medium">
                                            {definition.canBeEdited && definition.type !== "referenceData" && (
                                                <EditableMetadataValues
                                                    key={definition.id}
                                                    nodeId={businessDimension.id}
                                                    context={context}
                                                    contextId={contextId}
                                                    definition={definition}
                                                    values={values[0]}
                                                />
                                            )}
                                            {(!definition.canBeEdited || definition.type == "referenceData") && values.length && values[0]?.id && (
                                                <DisplayMetadataValue
                                                    key={definition.id}
                                                    metadataValue={values[0]}
                                                    definition={definition}
                                                    valueId={values[0].id}
                                                    nodeId={businessDimension.id}
                                                    contextId={contextId}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );

                                return acc;
                            }, [])
                        }
                </TableBody>
            </Table>
            <div>
                <div className="grid grid-cols-2 bg-slate-200 p-0 gap-[1px] rounded overflow-clip">

                </div>
            </div>
        </>

    );
};
