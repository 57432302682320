import { useStages } from "@metronome/api/useProcessInstance";
import LoadingMetronome from "@metronome/components/LoadingMetronome";
import { createFileRoute } from "@tanstack/react-router";
import { z } from "zod";
import * as DataGrid from "@metronome/components/DataGrid";
import { TableContainer } from "@metronome/components/TableContainer/TableContainer";
import { ErrorBoundary } from "@sentry/react";
import type { IStepInstanceLight } from "@metronome/types/StepInstance";
import {
	createColumnHelper,
	getCoreRowModel,
	useReactTable,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFilteredRowModel,
	getSortedRowModel,
	getExpandedRowModel,
} from "@tanstack/react-table";
import { useMemo } from "react";
import {
	FormattedDate,
	FormattedDateTimeRange,
	FormattedMessage,
} from "react-intl";
import { Lozenge } from "@metronome/components/Lozenge";
import { UpdateResolution } from "@metronome/features/UpdateResolution";
import { getIsResolutionDoneOrCancel } from "@metronome/utils/resolution";
import { useTranslatedStatusResolution } from "@metronome/types/Resolution";
import Flag from "@metronome/components/Flag";
import { useFormatDurationToString } from "@metronome/utils/durationHelpers";
import { StepSchedule } from "@metronome/features/StepSchedule";
import { calcTillDate } from "@metronome/utils/planby";
import { CustomItemSchedule } from "@metronome/features/UpdateStepInstanceSchedule";
import { fuzzyFilter } from "@metronome/utils/tableHelper";
import { isWithinRange } from "@metronome/utils/isWithinRange";
import { Button } from "@metronome/components/ui/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StepInstanceIcon } from "@metronome/components/IconStepInstance";

type DataTable = IStepInstanceLight & { stepName: string };

const columnHelpers = createColumnHelper<DataTable>();

const StepsTable = () => {
	const { processId } = Route.useParams();
	const { data: gates, isLoading, isFetched } = useStages(processId);
	const translatedStatusResolution = useTranslatedStatusResolution();

	const data = useMemo(
		() =>
			gates?.flatMap((g) =>
				g.steps.flatMap((s) =>
					s.stepInstances.map((i) => ({ ...i, stepName: s.name })),
				),
			),
		[gates],
	);

	const columns = useMemo(
		() => [
			columnHelpers.accessor("isFlagged", {
				enableColumnFilter: false,
				footer: (props) => props.column.id,
				header: () => <FontAwesomeIcon icon={["far", "flag"]} fixedWidth />,
				cell: (info) => (
					<Flag
						isFlagged={!!info.getValue()}
						id={info.row.original.id}
						context="step-instances"
					/>
				),
			}),
			columnHelpers.accessor("businessDimension.name", {
				footer: (props) => props.column.id,
				enableSorting: false,
				header: () => <FormattedMessage id="NAME" />,
				cell: (info) => (
					<div className="flex gap-2 items-center">
						<span className="font-semibold">{info.getValue()}</span>
						<span>
							<StepInstanceIcon type={info.row.original.type} />
							<span className="ms-1">{info.row.original.stepName}</span>
						</span>
					</div>
				),
			}),
			columnHelpers.accessor("resolution", {
				id: "resolution",
				footer: (props) => props.column.id,
				filterFn: "arrIncludes",
				meta: {
					modelRefArray: translatedStatusResolution,
				},
				header: () => <FormattedMessage id="STATE" />,
				cell: (info) => {
					if (getIsResolutionDoneOrCancel(info.getValue())) {
						return (
							<Lozenge appearance="default">
								<FormattedMessage
									id={`RESOLUTION.${info.row.original.resolution}`}
								/>
							</Lozenge>
						);
					}
					return (
						<UpdateResolution
							key={info.row.original.id}
							stepInstanceId={info.row.original.id}
							type={info.row.original.type}
							resolution={info.row.original.resolution}
							processId={processId}
						/>
					);
				},
			}),
			columnHelpers.accessor("schedule", {
				id: "scheduleLowerBandToUpperBand",
				enableColumnFilter: false,
				enableSorting: false,
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="SCHEDULE_BAND" />,
				cell: (info) => {
					if (info.row.original.type === "task") {
						return (
							<FormattedDateTimeRange
								from={new Date(info.getValue().scheduleLowerBand)}
								to={new Date(info.getValue().scheduleUpperBand)}
								month="numeric"
								day="numeric"
								year="numeric"
								hour="numeric"
								minute="numeric"
							/>
						);
					}
					return (
						<FormattedDate
							value={info.getValue().scheduleLowerBand}
							month="numeric"
							day="numeric"
							year="numeric"
							hour="numeric"
							minute="numeric"
						/>
					);
				},
			}),
			columnHelpers.accessor("schedule", {
				id: "planningAt",
				enableColumnFilter: false,
				enableSorting: false,
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="PLANNED_AT" />,
				cell: (info) => {
					return (
						<CustomItemSchedule
							stepId={info.row.original.id}
							since={
								info.getValue().plannedAt ?? info.getValue().scheduleLowerBand
							}
							till={calcTillDate(info.getValue())}
							schedule={info.getValue()}
							processId={processId}
							childrenAsChild={Boolean(!info.getValue().plannedAt)}
						>
							{!info.getValue().plannedAt ? (
								<Button variant="secondary">
									<FormattedMessage id="SET_PLANNED_AT" />
								</Button>
							) : undefined}
						</CustomItemSchedule>
					);
				},
			}),
			columnHelpers.accessor("schedule", {
				id: "duration",
				enableColumnFilter: false,
				enableSorting: false,
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="DURATION" />,
				cell: (info) => {
					const { estimatedDuration, plannedDuration } = info.getValue();
					const duration = useFormatDurationToString(
						estimatedDuration || plannedDuration,
					);
					return <span>{duration}</span>;
				},
			}),
			columnHelpers.accessor("schedule", {
				id: "SCHEDULE",
				enableColumnFilter: false,
				enableSorting: false,
				footer: (props) => props.column.id,
				header: () => <FormattedMessage id="SCHEDULE" />,
				cell: (info) => <StepSchedule schedule={info.getValue()} />,
			}),
		],
		[translatedStatusResolution, processId],
	);

	const instance = useReactTable<DataTable>({
		data: data ?? [],
		columns,
		getCoreRowModel: getCoreRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getExpandedRowModel: getExpandedRowModel(),
		enableColumnFilters: false,
		enableSorting: false,
		filterFns: {
			fuzzy: fuzzyFilter,
			isWithinRange,
		},
	});

	if (isLoading) {
		return <LoadingMetronome />;
	}
	return (
		<div className="w-full px-1">
			<DataGrid.TableHeader<DataTable>
				isLoading={isLoading}
				instance={instance}
			/>

			<div className="flex flex-row overflow-auto gap-2 w-full min-h-[35vh] min-w-48">
				<TableContainer>
					<div className={"flex flex-col grow overflow-auto"}>
						<ErrorBoundary>
							<DataGrid.TableBody<DataTable>
								instance={instance}
								isFetching={isLoading}
								isFetched={isFetched}
							/>
						</ErrorBoundary>
					</div>
				</TableContainer>
			</div>
		</div>
	);
};

const StepsSearchParams = z.object({
	search: z.string().optional(),
	stepId: z.string().optional(), // this optional step id is used for modal view
	page: z.number().optional().catch(1),
	pageSize: z.number().optional().catch(25),
	sortOrder: z.union([z.literal("asc"), z.literal("desc")]).optional(),
	sortBy: z.string().optional(),
	startFrom: z.string().optional(),
	startTo: z.string().optional(),
	resolution: z.array(z.string()).optional(),
	timeliness: z.string().optional(),
	responsibleIds: z.array(z.string()).optional(),
});

export const Route = createFileRoute(
	"/$workspaceId/stream/$streamId/process/$processId/steps",
)({
	validateSearch: (searchParams) => StepsSearchParams.parse(searchParams),
	component: () => <StepsTable />,
});
