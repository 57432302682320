import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type React from "react";
import { useIntl } from "react-intl";
import { useMediaQuery } from "react-responsive";
import styles from "./mainMenu.module.scss";
import {
	ActivityLogIcon,
	BarChartIcon,
	DashboardIcon,
} from "@radix-ui/react-icons";
import { Link, useMatchRoute } from "@tanstack/react-router";
// @ts-ignore
import Logo from "../assets/logotype.svg?react";
import clsx from "clsx";
import type { IUser } from "@metronome/types/User";
import type { IWorkspace } from "@metronome/types/Workspace";
import { HeaderUserMenu } from "./HeaderUserMenu";
import Notifications from "@metronome/features/Notification";
import { OpenInNewWindowIcon } from "@radix-ui/react-icons";


type InnerMenuItemProps = {
	children: React.ReactNode;
	title: string;
};

const InnerMenuItem: React.FC<InnerMenuItemProps> = ({ children, title }) => (
	<div className={styles.menu_item}>
		<div className={styles.icon}>{children}</div>
		<div className={styles.menu_title}>{title}</div>
	</div>
);

type Props = {
	user?: IUser;
	workspaces?: IWorkspace[];
};
export const AsideMenuMain: React.FC<Props> = ({ user, workspaces }) => {
	const intl = useIntl();
	const workspaceId = useWorkspaceId();

	const isOnboardingRoute = window.location.pathname === "/onboarding";

	const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

	const matchRoute = useMatchRoute();
	const singleStreamMatched = matchRoute({
		to: "/$workspaceId/stream/$streamId",
		fuzzy: true,
	});

	if (isOnboardingRoute) {
		return null;
	}

	return (
		<aside className={isMobile ? styles.container_mobile : styles.container}>
			<div className={styles.main} data-testid="main-menu">
				<Link
					to="/"
					className="h-[3.5rem] flex items-center justify-center mt-3"
				>
					<div
						className={clsx([
							"flex items-center justify-center aspect-square bg-primary rounded-md w-6/12",
							styles.logo_container,
						])}
					>
						<Logo />
					</div>
				</Link>
				<Link
					to="/$workspaceId/streams"
					params={{ workspaceId }}
					className={`${styles.menu_link} ${singleStreamMatched ? styles.active_item : ""}`}
					activeProps={{ className: styles.active_item }}
					activeOptions={{
						exact: false,
					}}
					data-testid="MENU.PROCESS"
				>
					<InnerMenuItem title={intl.formatMessage({ id: "MENU.PROCESS" })}>
						<DashboardIcon />
					</InnerMenuItem>
				</Link>
				<Link
					to="/$workspaceId/activities"
					params={{ workspaceId }}
					className={styles.menu_link}
					activeProps={{ className: styles.active_item }}
					data-testid="MENU.ACTIVITIES"
				>
					<InnerMenuItem title={intl.formatMessage({ id: "MENU.ACTIVITIES" })}>
						<ActivityLogIcon />
					</InnerMenuItem>
				</Link>
				<Link
					to="/$workspaceId/workload"
					params={{ workspaceId }}
					search={{
						method: "count",
					}}
					className={styles.menu_link}
					activeProps={{ className: styles.active_item }}
					data-testid="MENU.DASHBOARD"
					preload="intent"
				>
					<InnerMenuItem title={intl.formatMessage({ id: "DASHBOARD" })}>
						<BarChartIcon />
					</InnerMenuItem>
				</Link>
                {!!user?.canAccessStudio && (
                    <Link
                        className={styles.menu_link}
                        to={
                            window.VITE_BACKOFFICE_URL ||
                            import.meta.env.VITE_BACKOFFICE_URL
                        }
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        <InnerMenuItem title={intl.formatMessage({ id: "GO_ORCHESTRATOR",})}>
                            <OpenInNewWindowIcon />
                        </InnerMenuItem>
                    </Link>
                )}
				<div className="mt-auto mx-auto mb-4 flex flex-col gap-2">
					<Notifications />
					<HeaderUserMenu user={user} workspaces={workspaces} />
				</div>
			</div>
		</aside>
	);
};
