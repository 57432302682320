import {
	Table,
	TableBody,
	TableCell,
	TableRow,
} from "@metronome/components/ui/table";
import type { EResolution } from "@metronome/types/Resolution";
import type { ISchedule, IStepInstance } from "@metronome/types/StepInstance";
import {
	FormattedDate,
	FormattedDateTimeRange,
	FormattedMessage,
} from "react-intl";
import { CustomItemSchedule } from "./UpdateStepInstanceSchedule";
import { calcTillDate } from "@metronome/utils/planby";
import { LockClosedIcon } from "@radix-ui/react-icons";
import { isProcessActive } from "@metronome/utils/isProcessActive";
import type { ProcessState } from "@metronome/types/ProcessState";
import { UpdateResolution } from "./UpdateResolution";
import { Button } from "@metronome/components/ui/button";
import { UpdateStepInstanceDate } from "./UpdateStepInstanceDate";
import { useFormatDurationToString } from "@metronome/utils/durationHelpers";
import {useIsMobile} from "@metronome/hooks/useResponsive";

type StepStateProps = {
	schedule: ISchedule;
	resolution: EResolution;
	stepInstanceId: string;
	processInstanceId: string;
	type: IStepInstance["type"];
	processState: ProcessState;
};
export const StepState: React.FC<StepStateProps> = ({
	schedule,
	resolution,
	stepInstanceId,
	processInstanceId,
	processState,
	type,
}) => {
	const duration = useFormatDurationToString(
		schedule.estimatedDuration ?? schedule?.plannedDuration,
	);
    const isMobile = useIsMobile();

	const isFromAnActiveProcess = isProcessActive(processState);
	return (
		<Table className="bg-white">
			<TableBody>
                { 
                    !isMobile && (
                        <TableRow>
                            <TableCell className="capitalize font-semibold w-32">
                                <FormattedMessage id="SCHEDULE" />
                            </TableCell>
                            <TableCell>
                                {type === "task" ? (
                                    <FormattedDateTimeRange
                                        from={new Date(schedule.scheduleLowerBand)}
                                        to={new Date(schedule.scheduleUpperBand)}
                                        hour="numeric"
                                        day="numeric"
                                        month="numeric"
                                    />
                                ) : (
                                    <FormattedDate
                                        value={new Date(schedule.scheduleLowerBand)}
                                        minute="numeric"
                                        hour="numeric"
                                        day="numeric"
                                        month="numeric"
                                        year="numeric"
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                    )
                }
				<TableRow>
					<TableCell className="capitalize font-semibold w-32">
						<FormattedMessage id="PLANNED_AT" />
					</TableCell>
					<TableCell>
						{type === "task" ? (
							<CustomItemSchedule
								stepId={stepInstanceId}
								since={schedule.plannedAt ?? schedule.scheduleLowerBand}
								till={calcTillDate(schedule)}
								schedule={schedule}
								processId={processInstanceId}
								childrenAsChild={Boolean(!schedule.plannedAt)}
								mode={type === "task" ? "range" : "single"}
							>
								{!schedule.plannedAt ? (
									<Button variant="secondary">
										<FormattedMessage id="SET_PLANNED_AT" />
									</Button>
								) : undefined}
							</CustomItemSchedule>
						) : (
							<UpdateStepInstanceDate
								stepInstanceId={stepInstanceId}
								processInstanceId={processInstanceId}
								initialDate={
									new Date(schedule.plannedAt ?? schedule.scheduleLowerBand)
								}
							/>
						)}
					</TableCell>
				</TableRow>

				{!!duration && (
					<TableRow>
						<TableCell className="capitalize font-semibold">
							<FormattedMessage id="DURATION" />
						</TableCell>
						<TableCell>{duration}</TableCell>
					</TableRow>
				)}
				<TableRow>
					<TableCell className="capitalize font-semibold">
						<FormattedMessage id="STATUS" />
					</TableCell>
					<TableCell>
						{!isFromAnActiveProcess && (
							<>
								<LockClosedIcon />
								<FormattedMessage id="WARNING_PROCESS_CLOSED" />
							</>
						)}
						<UpdateResolution
							stepInstanceId={stepInstanceId}
							processState={processState}
							processId={processInstanceId}
							type={type}
							resolution={resolution}
						/>
					</TableCell>
				</TableRow>
			</TableBody>
		</Table>
	);
};
