import useWorkspaceId from "@metronome/hooks/useWorkspaceId";
import type { IUser } from "@metronome/types/User";
import {
	queryOptions,
	keepPreviousData,
	type UseQueryResult,
	useQuery,
} from "@tanstack/react-query";
import { apiGet } from "./api";

const workspaceUsersKeys = {
	single: (workspaceId: string) => [workspaceId, "workspaceUsers"] as const,
};

export const workspaceUsersOptions = (workspaceId: string) =>
	queryOptions({
		queryKey: workspaceUsersKeys.single(workspaceId),

		queryFn: () => apiGet<IUser[]>(`ws/${workspaceId}/users`),

		refetchOnWindowFocus: false,
		refetchOnMount: false,
		placeholderData: keepPreviousData,
	});

export default function useWorkspaceUsers(): UseQueryResult<
	IUser[] | undefined,
	Error
> {
	const workspaceId = useWorkspaceId();

	return useQuery(workspaceUsersOptions(workspaceId));
}
