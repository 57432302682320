import { z } from "zod";
import {
	IBusinessDimensionNode,
	ILightBusinessDimensionNode,
} from "./BusinessDimension";
import { IModelReference, IRankedModelReference } from "./ModelReference";
import { IParty } from "./Party";
import { EResolution } from "./Resolution.js";
import { UserDoer } from "./Doer";
import { ProcessState } from "./ProcessState";

export const IAgenda = z.object({
	id: z.string(),
	name: z.string(),
	description: z.string().optional(),
	optional: z.boolean(),
	duration: z.string(),
	completed: z.boolean(),
	completedOn: z.union([z.date(), z.string()]).optional(),
	completedBy: IParty.optional(),
});
export type IAgenda = z.infer<typeof IAgenda>;

export const EStepInstanceType = z.enum(["event", "task", "hook", "milestone"]);
export type EStepInstanceType = z.infer<typeof EStepInstanceType>;

export const IStepInstanceView = z.object({
	party: IParty,
	viewAt: z.number(),
});

export type IStepInstanceView = z.infer<typeof IStepInstanceView>;

export const ISchedule = z.object({
	// below are from the planning
	scheduleLowerBand: z.string(),
	scheduleUpperBand: z.string(),
	estimatedDuration: z.string(),
	// below are the regulator's job
	plannedAt: z.string().optional(),
	resolvedAt: z.string().optional(),
	plannedDuration: z.string().optional(),
	scheduledByRegulator: z.boolean().optional(),
});
export type ISchedule = z.infer<typeof ISchedule>;

export const StepInstanceCompletion = z.object({
	performedBy: UserDoer,

	performedAt: z.number(),
});
export type StepInstanceCompletion = z.infer<typeof StepInstanceCompletion>;

export const IStepInstance = z.object({
	id: z.string().min(1),
	name: z.string().optional(),
	resolution: EResolution,
	stage: IRankedModelReference,
	step: IRankedModelReference,
	stepTemplate: IModelReference,
	schedule: ISchedule,
	objective: z.string(),
	groundRules: z.string(),
	processInstance: z.object({
		id: z.string(),
        contextNodeId: z.string(),
		name: z.string(),
		state: ProcessState,
		organizationId: z.string(),
		processStream: IModelReference,
		processTemplate: IModelReference,
	}),
	agenda: z.array(IAgenda),
	isFlagged: z.boolean(),
	showTime: z.boolean(),
	dueDateModified: z.boolean(),
	notLaterThanModified: z.boolean(),
	businessDimension: IBusinessDimensionNode,
	isBlocked: z.boolean(),
	views: z.array(IStepInstanceView).optional(),
	type: EStepInstanceType,
	completion: StepInstanceCompletion.optional(),
});

export type IStepInstance = z.infer<typeof IStepInstance>;

export const IStepRequirements = z.object({
	name: z.enum(["forms", "attachments", "previousStepInstances"]),
	fulfilled: z.boolean(),
	ref: z.array(
		z.object({
			id: z.string().min(1),
			name: z.string(),
		}),
	),
});
export type IStepRequirements = z.infer<typeof IStepRequirements>;

export const IStepTemplateFilter = z.object({
	name: z.string().min(1),
	ids: z.array(z.string()),
});
export type IStepTemplateFilter = z.infer<typeof IStepTemplateFilter>;

export const IFilters = z.object({
	stepTypes: EStepInstanceType.optional(),
	labels: z.array(IModelReference),
	processStreams: z.array(IModelReference),
	stepTemplates: z.array(IStepTemplateFilter),
	nodeTypes: z.array(IModelReference),
});
export type IFilters = z.infer<typeof IFilters>;

export type StepInstanceContext = "activities";

export const IStepInstanceLight = IStepInstance.pick({
	id: true,
	type: true,
	name: true,
	schedule: true,
	isFlagged: true,
	isBlocked: true,
	businessDimension: true,
	resolution: true,
}).extend({
	processInstanceId: z.string(),
});

export type IStepInstanceLight = z.infer<typeof IStepInstanceLight>;

const ClusterGroup = z.object({
	totalItems: z.number(),
	page: z.number(),
	nextPage: z.number(),
	prevPage: z.number(),
	totalPages: z.number(),
	pageSize: z.number(),
	results: z.array(
		z.object({
			maxDateTime: z.string(),
			minDateTime: z.string(),
			resource: ILightBusinessDimensionNode,
			stepInstances: z.array(
				IStepInstanceLight.extend({
					name: z.string(),
					processInstanceId: z.string(),
				}),
			),
			stepInstancesCount: z.number(),
		}),
	),
});

export const ClusteredStepInstances = z.object({
	stepTemplateId: z.string(),
	stepTemplateName: z.string(),
	groups: ClusterGroup,
	groupsCount: z.number(),
	maxDateTime: z.string(),
	minDateTime: z.string(),
});

export type ClusteredStepInstances = z.infer<typeof ClusteredStepInstances>;
