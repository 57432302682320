import useWorkspaceUsers from "@metronome/api/useWorkspaceUsers";

export const UserName = ({ userId }: { userId: string }) => {
	const { data: users } = useWorkspaceUsers();
	const currentUser = users?.find((u) => u.metronomeUserId === userId);
	if (currentUser)
		return (
			<span>
				{currentUser?.firstName} {currentUser?.lastName}
			</span>
		);
	return null;
};
