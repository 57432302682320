import { singleProcessStreamsOptions } from "@metronome/api/useProcessStreamInstances";
import { useQuery } from "@tanstack/react-query";
import { createFileRoute } from "@tanstack/react-router";
import { PageTitle } from "@metronome/context/PageData";
import { ProcessStreamTemplate } from "@metronome/features/ProcessStreamTemplate";
import { FormattedMessage, useIntl } from "react-intl";
import { useOrganizationData } from "@metronome/context/OrganizationData";
import {
	Tabs,
	TabsContent,
	TabsList,
	TabsTrigger,
} from "@metronome/components/ui/tabs";
import { PortfolioProcessTimelineLoader } from "./-timeline";
import { ActiveViewProvider } from "@metronome/context/ActiveViewData";
import { UserProcessConfigLoader } from "@metronome/features/UserProcessConfigLoader";
import useUserPreferences from "@metronome/api/useUserPreference";
import { useCallback, useState } from "react";
import * as v from "valibot";
import type { IProcessStream } from "@metronome/types/ProcessStream";
import { DateRangePicker } from "@metronome/components/ui/dateRangePicker";
import type { DateRange } from "react-day-picker";
import { addMonths, subMonths } from "date-fns";

const portfolioParams = v.object({
	nodeReferenceId: v.optional(v.string()),
	stepTemplateId: v.optional(v.string()),
});

export const Route = createFileRoute(
	"/$workspaceId/stream/$streamId/portfolio",
)({
	validateSearch: (searchParams) => v.parse(portfolioParams, searchParams),
	loader: async ({ context, params }) => {
		const { queryClient } = context;
		const { streamId, workspaceId } = params;

		const promises: Array<Promise<unknown>> = [];

		promises.push(
			queryClient.ensureQueryData(
				singleProcessStreamsOptions(workspaceId, streamId),
			),
		);

		await Promise.all(promises);
		return;
	},
	component: () => {
		const { streamId, workspaceId } = Route.useParams();
		const { data: processStream } = useQuery(
			singleProcessStreamsOptions(workspaceId, streamId),
		);
		if (processStream) return <PortfolioPage processStream={processStream} />;
	},
});

const PortfolioPage = ({
	processStream,
}: { processStream: IProcessStream }) => {
	const [activeTab, setActiveTab] = useState("list");
	const { organizations } = useOrganizationData();
	const intl = useIntl();
	const [range, setRange] = useState<DateRange>({
		from: subMonths(new Date(), 3),
		to: addMonths(new Date(), 1),
	});

	const onChangeDateRange = useCallback((range: DateRange) => {
		setRange(range);
	}, []);
	const organization = organizations?.find(
		(orga) => orga.id === processStream?.organization.id,
	);

	const state = "active"; // to refactor
	const PREFERENCE_KEY = `metronome.processStream.instances.active.${processStream.id}`;

	const { data: preferences } = useUserPreferences(PREFERENCE_KEY);

	return (
		<ProcessStreamTemplate processStream={processStream} isLoading={false}>
			<PageTitle organizationName={organization?.name}>
				{intl.formatMessage({ id: "MENU.PROCESS_STREAM" })}
			</PageTitle>
			<DateRangePicker range={range} onValidate={onChangeDateRange} />
			<Tabs className="mt-2" value={activeTab} onValueChange={setActiveTab}>
				<TabsList className="bg-white">
					<TabsTrigger className="font-bold" value="list">
						<FormattedMessage id="LIST" />
					</TabsTrigger>
					<TabsTrigger className="font-bold" value="timeline">
						<FormattedMessage id="TIMELINE" />
					</TabsTrigger>
				</TabsList>
				<TabsContent value="list">
					<ActiveViewProvider preferences={preferences}>
						<UserProcessConfigLoader
							key={state}
							processStreamId={processStream.id}
							preferenceKey={PREFERENCE_KEY}
							preferences={preferences}
							range={range}
						/>
					</ActiveViewProvider>
				</TabsContent>
				<TabsContent value="timeline">
					<PortfolioProcessTimelineLoader
						range={range}
						streamId={processStream.id}
					/>
				</TabsContent>
			</Tabs>
		</ProcessStreamTemplate>
	);
};
