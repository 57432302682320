import {
	useCreateStepResources,
	useDeleteStepResources,
	useStepAvailableResources,
	useStepResources,
} from "@metronome/api/useStepResources";
import { ComboboxPaginated } from "@metronome/components/ComboBoxPaginated";
import { Button } from "@metronome/components/ui/button";
import {
	Dialog,
	DialogContent,
	DialogTrigger,
} from "@metronome/components/ui/dialog";
import {
	Table,
	TableBody,
	TableRow,
	TableCell,
} from "@metronome/components/ui/table";
import { useState, useMemo, useCallback } from "react";
import { FormattedMessage } from "react-intl";
import { Node } from "./Node";
import { BlockedIcon } from "@metronome/components/BlockedIcon";
import { PlusIcon } from "@radix-ui/react-icons";

type ResourceAllocationsDefProps = {
	nodeReferenceDefinitionId: string;
	stepInstanceId: string;
};
const ResourceAllocationsDef: React.FC<ResourceAllocationsDefProps> = ({
	nodeReferenceDefinitionId,
	stepInstanceId,
}) => {
	const [search, setSearch] = useState("");
	const [value, setValue] = useState("");

	const { mutate: createResource } = useCreateStepResources(stepInstanceId);

	const { data, fetchNextPage, hasNextPage, isLoading } =
		useStepAvailableResources(
			stepInstanceId,
			nodeReferenceDefinitionId,
			search,
		);

	const nodes = useMemo(() => {
		return data?.pages
			.flatMap((p) => p.results)
			.map((v) => ({
				label: v.name,
				value: v.id,
			}));
	}, [data]);

	const addNodeReference = useCallback(() => {
		createResource({
			nodeId: value,
			nodeReferenceDefinitionId,
		});
	}, [value, nodeReferenceDefinitionId, createResource]);
	if (isLoading) return null;
	return (
		<>
			<h3>
				<FormattedMessage id="SEARCH" />
			</h3>
			{!!nodes && !!nodes?.length ? (
				<ComboboxPaginated
					setSearchValue={setSearch}
					value={value}
					setValue={setValue}
					hasNextPage={hasNextPage}
					items={nodes}
					fetchNextPage={fetchNextPage}
				/>
			) : (
				<FormattedMessage id="NO_RESSOURCE_AVAILABLE" />
			)}
			<Button
				className="w-fit ml-auto"
				disabled={!value}
				onClick={() => addNodeReference()}
			>
				<FormattedMessage id="SAVE" />
			</Button>
		</>
	);
};

type ResourcesProps = {
	stepInstanceId: string;
	level?: "step" | "process";
};
/**
 * NodesReferences lacks a way to navigate or show the details of a node: open a popover with
 * the nodes metadata and detail for example
 */
export const Resources: React.FC<ResourcesProps> = ({
	stepInstanceId,
	level = "step",
}) => {
	const { data: resources } = useStepResources(stepInstanceId);
	const { mutate: deleteResource } = useDeleteStepResources(stepInstanceId);

	if (resources?.length === 0) {
		return null;
	}
	return (
		<>
			<div className="uppercase text-xs font-semibold mt-2">
				<FormattedMessage id="RESOURCES" />
			</div>
			<Table className="bg-white">
				<TableBody>
					{resources?.map(
						({
							nodeReferenceDefinitionId,
							nodeReferenceDefinitionName,
							references,
							allowMultiple,
							ownershipLevel,
						}) => (
							<TableRow key={nodeReferenceDefinitionId}>
								<TableCell>
									<span className="me-1">{nodeReferenceDefinitionName}</span>
									{ownershipLevel !== level && (
										<BlockedIcon
											side="right"
											tooltipMessage="BLOCKED_RESOURCE"
										/>
									)}
								</TableCell>
								<TableCell className="font-medium">
									{references?.map((ref) => (
										<>
											<Node
												key={ref.referencedNode.id}
												id={ref.referencedNode.id}
												topButton={
													level === ownershipLevel && (
														<Button
															variant="ghostDestructive"
															onClick={() =>
																deleteResource({
																	nodeReferenceDefinitionId:
																		nodeReferenceDefinitionId,
																	nodeId: ref.referencedNode.id,
																})
															}
														>
															<FormattedMessage id="REMOVE" />
														</Button>
													)
												}
											>
												{ref.referencedNode?.name}
											</Node>
										</>
									))}
									{ownershipLevel === level &&
										(references?.length === 0 || allowMultiple) && (
											<Dialog>
												<DialogTrigger asChild>
													<Button
														size={allowMultiple ? "icon" : "default"}
														variant="secondary"
													>
														{allowMultiple ? (
															<PlusIcon />
														) : (
															<FormattedMessage id="ADD" />
														)}
													</Button>
												</DialogTrigger>
												<DialogContent>
													<ResourceAllocationsDef
														nodeReferenceDefinitionId={
															nodeReferenceDefinitionId
														}
														stepInstanceId={stepInstanceId}
													/>
												</DialogContent>
											</Dialog>
										)}
								</TableCell>
							</TableRow>
						),
					)}
				</TableBody>
			</Table>
		</>
	);
};
