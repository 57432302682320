import { formatDuration } from "date-fns/formatDuration";
import { parse } from "duration-fns/dist/parse";
import { isZero } from "duration-fns/dist/isZero";
import { fr } from "date-fns/locale/fr";
import { enGB as en } from "date-fns/locale/en-GB";
import { useIntl } from "react-intl";

const locales = { fr, en };

export function durationToString(durationString: string): string {
	return durationString === "0:00:00" ? "" : durationString;
}

export function useFormatDurationToString(
	durationString: string | undefined,
): string {
	const intl = useIntl();
	if (!durationString || durationString === "P") return "";
	const parsedDuration = parse(durationString);
	if (isZero(parsedDuration)) return "";
	return formatDuration(parsedDuration, {
		format: ["days", "hours", "minutes"],
		locale: locales[intl.locale as keyof typeof locales],
	});
}
