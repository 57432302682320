import { useProcessResources } from "@metronome/api/useProcessInstance";
import { BlockedIcon } from "@metronome/components/BlockedIcon";
import { TableCell, TableRow } from "@metronome/components/ui/table";
import { FormattedMessage } from "react-intl";
import { Node } from "@metronome/features/Node";
import { Button } from "@metronome/components/ui/button";
import { Badge } from "@metronome/components/ui/badge";

export const ProcessResources: React.FC<{ processId: string }> = ({
	processId,
}) => {
	const level = "process" as const;
	const { data: resources } = useProcessResources(processId);
	if (resources) {
		return (
			<>
				{resources?.map(
					({
						nodeReferenceDefinitionId,
						nodeReferenceDefinitionName,
						references,
						allowMultiple,
						ownershipLevel,
					}) => (
						<TableRow key={nodeReferenceDefinitionId}>
							<TableCell className="">
								<span className="me-1 font-medium">
									{nodeReferenceDefinitionName}
								</span>
								{ownershipLevel !== level && (
									<BlockedIcon side="right" tooltipMessage="BLOCKED_RESOURCE" />
								)}
							</TableCell>
							<TableCell className="font-medium inline-block items-center mw-100 gap-2 ">
								{references?.map((ref) => (
									<>
										<Node
											key={ref.referencedNode.id}
											id={ref.referencedNode.id}
											topButton={
												level === ownershipLevel && (
													<Button
														variant="ghostDestructive"
														onClick={
															() => null
															// deleteResource({
															// 	nodeReferenceDefinitionId:
															// 		nodeReferenceDefinitionId,
															// 	nodeId: ref.referencedNode.id,
															// })
														}
													>
														<FormattedMessage id="REMOVE" />
													</Button>
												)
											}
										>
                                            
											<Badge variant="outline"
                                                   className="overflow-hidden text-ellipsis text-nowrap">
												{ref.referencedNode?.name}
											</Badge>
										</Node>
									</>
								))}
							</TableCell>
						</TableRow>
					),
				)}
			</>
		);
	}
	return null;
};
