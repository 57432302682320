import { z } from "zod";

export const ProcessState = z.enum([
	"draft", // dispatched
	"ready", // scheduled
	"active",
	"closed",
	"archived",
]);

export type ProcessState = z.infer<typeof ProcessState>;
